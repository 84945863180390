import React from 'react';
import { useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import dataBus from '../variables';
import Modal from "react-modal";
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import Search from '../widgets/search';
import { di, dict } from '../dict';
const axios = require('axios').default;

const short_days = ['M', 'T', 'W', 'R', 'F', 'S', 'D'];


class EditWorkout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'exercises': [],
            'name': [],
            'days': [],
            'disp_days': [],
            'ready': false,
            'editingWorkouts': false,
            'addingExercises': false,
            'changed': false,
            'editingDays': false,
            'editingName': false,
            'loading': false,
            'sureDelete': false,
        };
        this.prepareExercises();
    }

    reset() {
        let exs = [];
        for (var i = 0; i < dataBus.workoutParams.exercises.length; i++) {
            let id = dataBus.workoutParams.exercises[i];
            exs.push(id);
        }
        this.setState({
            'exercises': exs,
            'name': structuredClone(dataBus.workoutParams.name),
            'days': structuredClone(dataBus.workoutParams.object['repeat_days']),
            'disp_days': structuredClone(dataBus.workoutParams.disp_days),
            'ready': false,
            'editingWorkouts': false,
            'addingExercises': false,
            'changed': false,
            'editingDays': false,
            'editingName': false,
        });
        this.prepareExercises();
    }


    prepareExercises() {
        let exs = [];
        for (var i = 0; i < dataBus.workoutParams.exercises.length; i++) {
            let id = dataBus.workoutParams.exercises[i];
            exs.push(id);
        }
        this.state['exercises'] = exs;
        this.state.days = structuredClone(dataBus.workoutParams.object['repeat_days']);
        this.state.disp_days = structuredClone(dataBus.workoutParams.disp_days);
        this.state.name = structuredClone(dataBus.workoutParams.name);
        if (this.componentDidCatch) {
            console.log('been mounted');
        }
        this.getMissingExercises();
    }

    async getMissingExercises() {
        let exs = this.state.exercises;
        let toAdd = [];
        for (var i = 0; i < exs.length; i++) {
            if (dataBus.exerciseDb.findIndex((e) => e === exs[i]) === -1) {
                toAdd.push(exs[i]);
            }
        }
        if (toAdd.length !== 0) {
            const response = await axios.post(`https://baluan.siranov.com/api/dailyWorkout`, {
                workout_ids: toAdd,
            });
            let data = response.data;
            for (var i = 0; i < data.length; i++) {
                dataBus.exerciseDb.push(data[i]);
            }
            this.setState({
                'ready': true,
            });
        }
    }

    editWorkout() {
        let cur = this.state.editingWorkouts;
        this.setState({
            editingWorkouts: !cur,
        });
    }

    showAddExercise() {
        this.setState({
            addingExercises: true,
        });
    }

    closeAddExercise() {
        this.setState({
            addingExercises: false,
            editingName: false,
            editingDays: false,
        });
    }

    async removeExercise(id) {
        let exs = structuredClone(this.state.exercises);
        let ind = exs.findIndex((e) => e === id);
        if (ind !== -1) {
            exs.splice(ind, 1);
        }
        this.setState({
            'exercises': exs,
        });
        if (exs.length === 0) {
            this.setState({
                editingWorkouts: false,
            });
        }
        await sleep(20);
        this.changed();
    }

    async addExercises(exs) {
        let curExs = structuredClone(this.state.exercises);
        for (let i = 0; i < exs.length; i++) {
            curExs.push(exs[i]['id']);
        }
        this.setState({
            exercises: curExs,
            addingExercises: false,
        });
        await sleep(20);
        this.getMissingExercises();
        this.changed();
    }

    editDays() {
        this.setState({
            editingDays: true,
        });
    }

    editName() {
        this.setState({
            editingName: true,
        });
    }

    changed() {
        let isChanged = false;
        let curExs = this.state.exercises;
        if (curExs.length !== dataBus.workoutParams.exercises.length) {
            isChanged = true;
        } else {
            for (let i = 0; i < curExs.length; i++) {
                if (curExs[i] !== dataBus.workoutParams.exercises[i]) {
                    isChanged = true;
                }
            }
        }
        if (this.state.name !== dataBus.workoutParams.name) {
            isChanged = true;
        }
        if (this.state.disp_days.toString()
            !== dataBus.workoutParams.disp_days.toString()) {
            isChanged = true;
        }
        this.setState({
            changed: isChanged,
        });
    }

    async newName() {
        let name = document.getElementById('workout-name-input').value;
        if (name !== "") {
            this.setState({
                name: name,
            });
            await (20);
            this.closeAddExercise();
            this.changed();
        }
    }

    async newDays() {
        let ret_short = [];
        let ret_mid = [];
        for (let i = 0; i < 7; i++) {
            let val = document.getElementById(`day-input-${i}`).checked;
            if (val) {
                ret_short.push(short_days[i]);
                ret_mid.push(dict['semi-days'][di.c][i]);
            }
        }
        let disp = ret_mid.join(", ");
        this.setState({
            days: ret_short,
            disp_days: disp,
        });
        await (20);
        this.closeAddExercise();
        this.changed();
    }

    async updateData() {
        this.setState({
            loading: true,
        });
        let user_workouts = structuredClone(dataBus.user.workouts);
        let obj = {
            'workout_ids': this.state.exercises,
            'repeat_days': this.state.days,
            'name': this.state.name,
        };
        if (dataBus.workoutParams.index !== -1) {
            user_workouts[dataBus.workoutParams.index] = obj;
        } else {
            user_workouts.push(obj);
        }
        const resp = await axios.post(`https://baluan.siranov.com/api/editWorkout`, {
            workouts: user_workouts,
            id: dataBus.user.id,
        });
        let prev = structuredClone(dataBus.workoutParams);
        let index = prev['index'];
        index = index !== -1 ? index : user_workouts.length - 1;
        dataBus.workoutParams = {
            'source': 'edit',
            'index': index,
            'exercises': this.state.exercises,
            'object': obj,
            'name': this.state.name,
            'disp_days': this.state.disp_days,
        };
        dataBus.user.workouts = structuredClone(user_workouts);
        this.setState({
            'loading': false,
            'changed': false,
        });
    }

    async deleteWorkout(cb) {
        this.setState({
            sureDelete: false,
            loading: true,
        });
        let index = dataBus.workoutParams.index;
        if (index !== -1) {
            let user_workouts = structuredClone(dataBus.user.workouts);
            user_workouts.splice(index, 1);
            const resp = await axios.post(`https://baluan.siranov.com/api/editWorkout`, {
                workouts: user_workouts,
                id: dataBus.user.id,
            });
            dataBus.user.workouts = structuredClone(user_workouts);
            cb();
        }
    }

    render() {
        return <div class="flex left-0 top-0 flex-col justify-center items-center relative w-[100%] h-[100%]">
            <div class="h-[70px]"></div>
            <div class="flex w-[100%] flex-col flex-start items-start pl-[20px] text-start font-bold text-[20px]">{dict['name'][di.c]}</div>
            <div class="h-[5px]"></div>
            <div class="flex w-[100%] pl-[20px] pr-[20px] flex-row justify-between items-center">
                <div class="">{this.state.name}</div>
                <EditOutlinedIcon onClick={() => { this.editName(); }}
                    sx={{ color: 'rgba(0, 0, 0, 0.7)' }} />
            </div>
            <div class="h-[20px]"></div>
            <div class="flex w-[100%] flex-col flex-start items-start pl-[20px] text-start font-bold text-[20px]">{dict['days-word'][di.c]}</div>
            <div class="h-[5px]"></div>
            <div class="flex w-[100%] pl-[20px] pr-[20px] flex-row justify-between items-start">
                <div class="w-[50%] text-black/[0.7]">{this.state.disp_days}</div>
                <EditOutlinedIcon onClick={() => { this.editDays(); }}
                    sx={{ color: 'rgba(0, 0, 0, 0.7)' }} />
            </div>
            <div class="h-[20px]"></div>
            <div class="flex flex-row justify-between items-center w-[100%] pr-[20px] pl-[20px]">
                <div class="text-start font-bold text-[20px]">{`${dict['exercises-word'][di.c]} (${this.state.exercises.length})`}</div>
                <div onClick={() => { this.editWorkout(); }}>{this.state.exercises.length !== 0 ?
                    (!this.state.editingWorkouts ? <EditOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.7)' }} /> :
                        <DoneOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.7)' }} />) : <></>}</div>
            </div>
            <div class="h-[20px]"></div>
            <ExerciseRender showAdd={() => { this.showAddExercise(); }} remove={(id) => { this.removeExercise(id); }}
                editing={this.state.editingWorkouts} exercises={this.state.exercises} />
            {dataBus.workoutParams.index !== -1 ? <div onClick={() => { this.setState({ sureDelete: true }); }}
                class="flex mb-[20px] items-center justify-center w-[100%] text-red-500">{dict['delete-this'][di.c]}</div> : <></>}
            {this.state.changed ? <div class="h-[50px]"></div> : <></>}
            <div class="h-[50px] fixed w-[100%] top-[0px] left-[0px] bg-[#6138D3] text-white text-[16px] flex flex-row justify-between items-center p-[10px]">
                <BackButton />
                <div>{dict['edit-workout'][di.c]}</div>
                {!this.state.changed ? <div class="h-[20px] w-[20px]" /> : <div onClick={() => { this.reset(); }}
                    class="font-normal text-red-300">{dict['cancel'][di.c]}</div>}
            </div>
            <Modal isOpen={this.state.editingName}
                ariaHideApp={false}
                style={{
                    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.6)', overflow: 'scroll' },
                    content: { backgroundColor: '#E6E6E6', overflow: 'auto', overscrollBehavior: 'none' }
                }}
                preventScroll={true}
                className="w-[calc(100%-40px)] outline-none m-0 p-0 border-0 h-[200px] rounded-tr-[15px] rounded-[20px] bg-[#E6E6E6] fixed bottom-[calc(50%-100px)] left-[20px] border-none"
                onRequestClose={() => { this.closeAddExercise(); }}
                contentLabel="">
                <NameChanger newName={() => { this.newName(); }} close={() => { this.closeAddExercise(); }} />
            </Modal>
            <Modal isOpen={this.state.editingDays}
                ariaHideApp={false}
                style={{
                    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.6)', overflow: 'scroll' },
                    content: { backgroundColor: '#E6E6E6', overflow: 'auto', overscrollBehavior: 'none' }
                }}
                preventScroll={true}
                className="w-[calc(100%-40px)] outline-none m-0 p-0 border-0 h-[500px] rounded-tr-[15px] rounded-[20px] bg-[#E6E6E6] fixed bottom-[calc(50%-250px)] left-[20px] border-none"
                onRequestClose={() => { this.closeAddExercise(); }}
                contentLabel="">
                <DayPicker days={this.state.days} newDays={() => { this.newDays(); }}
                    close={() => { this.closeAddExercise(); }} />
            </Modal>
            <Modal isOpen={this.state.addingExercises}
                ariaHideApp={false}
                style={{
                    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.6)', overflow: 'scroll' },
                    content: { backgroundColor: '#E6E6E6', overflow: 'auto', overscrollBehavior: 'none' }
                }}
                preventScroll={true}
                className="w-[calc(100%-40px)] outline-none m-0 p-0 border-0 h-[calc(100%-40px)] rounded-tr-[15px] rounded-[20px] bg-[#E6E6E6] fixed bottom-[20px] left-[20px] border-none"
                onRequestClose={() => { this.closeAddExercise(); }}
                contentLabel="">
                <Search addExercises={(exs) => { this.addExercises(exs); }}
                    close={() => { this.closeAddExercise(); }} />
            </Modal>
            <Modal isOpen={this.state.loading}
                ariaHideApp={false}
                style={{
                    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.6)', overflow: 'scroll' },
                    content: { backgroundColor: '#E6E6E6', overflow: 'auto', overscrollBehavior: 'none' }
                }}
                preventScroll={true}
                className="w-[calc(100%-40px)] outline-none m-0 p-0 border-0 h-[200px] rounded-tr-[15px] rounded-[20px] bg-[#E6E6E6] fixed bottom-[calc(50%-100px)] left-[20px] border-none"
                onRequestClose={() => { }}
                contentLabel="">
                <div class="flex w-[100%] h-[100%] justify-center items-center">{dict['updating-information'][di.c]}</div>
            </Modal>
            <Modal isOpen={this.state.sureDelete}
                ariaHideApp={false}
                style={{
                    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.6)', overflow: 'scroll' },
                    content: { backgroundColor: '#E6E6E6', overflow: 'auto', overscrollBehavior: 'none' }
                }}
                preventScroll={true}
                className="w-[calc(100%-40px)] outline-none m-0 p-0 border-0 h-[200px] rounded-tr-[15px] rounded-[20px] bg-[#E6E6E6] fixed bottom-[calc(50%-100px)] left-[20px] border-none"
                onRequestClose={() => { }}
                contentLabel="">
                <SureDelete cancel={() => { this.setState({ sureDelete: false }) }}
                    callback={(cb) => { this.deleteWorkout(cb); }} />
            </Modal>
            {this.state.changed ? <div onClick={() => { this.updateData(); }}
                class="fixed flex justify-center bottom-0 left-0
             items-center h-[50px] w-[100%] bg-[#6138D3] text-white">{dict['save-changes'][di.c]}</div> : <></>}
        </div>
    }
}

function ExerciseRender(props) {
    let ret = [];
    ret.push(<div key={'yo'} onClick={() => { props.showAdd(); }}
        class="w-[calc(100%-40px)] h-[50px] flex items-center justify-center bg-white mb-[20px] rounded-[10px] flex">
        {"+ "+dict['add-exercise'][di.c]}
    </div>);
    for (var b = 0; b < props.exercises.length; b++) {
        const i = b;
        const id = props.exercises[b];
        let index = dataBus.exerciseDb.findIndex((e) => e['id'] === id);
        let obj = { 'name': '', 'img': '' };
        if (index !== -1) {
            obj = {
                'name': dataBus.exerciseDb[index]['name'],
                'img': dataBus.exerciseDb[index]['preview'],
            };
        }
        ret.push(<div key={i} class="w-[calc(100%-40px)] flex flex-row items-center p-[20px] h-[90px] ml-[20px] mr-[20px] mb-[20px] rounded-[10px] bg-white">
            <div class="w-[calc(100%-24px)] h-[100%] flex flex-row items-center">
                <div class="h-[60px] w-[60px] rounded-[15px] mr-[20px] bg-blue-100">
                    {obj['img'] !== "" ? <img src={obj['img']} class="w-[100%]  h-[100%] object-cover rounded-[15px]"></img> : <></>}
                </div>
                <div>{obj['name']}</div>
            </div>
            {props.editing ? <DeleteIcon onClick={() => { props.remove(id) }}
                sx={{ color: 'rgba(0, 0, 0, 0.7)' }} /> : <></>}
        </div>);
    }

    return <div class="flex flex-col relative w-[100%] justify-start items-center">
        {ret.map((e) => e)}
    </div>
}


function SureDelete(props) {
    let history = useHistory();
    function deleteWorkout() {
        props.callback((() => {
            history.push("/edit");
        }));
    }
    return <div class="w-[100%] h-[100%] flex flex-col items-center justify-between p-[20px]">
        <div class="text-center">{dict['are-you-sure'][di.c]}</div>
        <div class="w-[100%] flex flex-row items-center justify-between">
            <div onClick={() => { props.cancel(); }}
                class="w-[calc(50%-20px)] flex justify-center items-center h-[40px] rounded-[15px] bg-green-700 text-white">{dict['cancel'][di.c]}</div>
            <div onClick={() => { deleteWorkout(); }}
                class="w-[calc(50%-20px)] flex justify-center items-center h-[40px] rounded-[15px] bg-red-600 text-white">{dict['delete-big'][di.c]}</div>
        </div>
    </div>
}


function DayPicker(props) {
    let ret = [];
    for (let b = 0; b < 7; b++) {
        const i = b;
        let isClicked = props.days.findIndex((e) => e === short_days[i]) !== -1;
        ret.push(<div key={i} class="flex h-[30px] mb-[20px] flex-row w-[100%] justify-between items-center">
            <div>{dict['display-week'][di.c][i]}</div>
            <input defaultChecked={isClicked}
                id={`day-input-${i}`}
                type='checkbox' class="h-[30px] outline-none border-none w-[30px] rounded-[5px] bg-white"></input>
        </div>);
    }

    return <div class="w-[100%] p-[20px] flex flex-col justify-start items-start">
        <div class="w-[100%] flex flex-row justify-between items-center">
            <div class="text-[24px] font-bold">{dict['edit-days'][di.c]}</div>
            <CloseIcon onClick={() => { props.close(); }} />
        </div>
        <div class="h-[20px]"></div>
        {ret.map((e) => e)}
        <div class="h-[20px]"></div>
        <div onClick={() => { props.newDays(); }}
            class="h-[30px] self-end w-[80px] flex justify-center items-center text-white rounded-[10px] bg-[#6138D3]">{dict['save'][di.c]}</div>
    </div>
}

function NameChanger(props) {
    return <div class="w-[100%] p-[20px] flex flex-col justify-start">
        <div class="w-[100%] flex flex-row items-center justify-between">
            <div class="text-[24px] font-bold">{dict['name'][di.c]}</div>
            <CloseIcon onClick={() => { props.close(); }} />
        </div>
        <div class="h-[10px]"></div>
        <input id="workout-name-input"
            placeholder={"Name of the workout..."}
            class="outline-none w-[100%] pl-[10px] h-[40px] rounded-[10px] bg-white"></input>
        <div class="h-[40px]" />
        <div onClick={() => { props.newName(); }} class="h-[30px] self-end w-[80px] flex justify-center items-center text-white rounded-[10px] bg-[#6138D3]">{dict['save'][di.c]}</div>
    </div>;
}


function BackButton() {
    let history = useHistory();
    function back() {
        history.push("/edit");
    }
    return <ChevronLeftIcon onClick={() => { back(); }} sx={{ color: 'white' }} />;
}

export default EditWorkout;

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

import React from 'react';
import { useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import dataBus from "../variables";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { dict, di } from '../dict';
import wands from '../utils/waitAndScroll';
const axios = require('axios').default;






class PastWorkouts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            workouts: [],
            loaded: false,
        }
        wands();
        this.prepareWorkouts();
    }

    async prepareWorkouts() {
        let workouts = this.state.workouts;
        if (JSON.stringify(dataBus.localSets) !== "{}") {
            workouts.push({
                'date': dict['today'][di.c],
                'workouts': dataBus.localSets['workout_names'],
                'relative': "N/A",
            });
        }
        const n = 7 - workouts.length;
        for (var i = 0; i < n; i++) {
            workouts.push({});
        }
        this.state.workouts = workouts;
        if (JSON.stringify(dataBus.local7) !== "[]") {
            workouts = [];
            let data = dataBus.local7;
            const td = new Date();
            let rettd = `${td.getDate()}/${td.getMonth() + 1}/${td.getFullYear()}`;
            for (var i = 0; i < data.length; i++) {
                let std = data[i]['Date'].split("/").map((e) => parseInt(e));
                let d = new Date(std[2], std[1] - 1, std[0]);
                let ret = `${dict['months'][di.c][std[1] - 1]} ${std[0]} (${dict['week'][di.c][d.getDay()]})`;
                ret = data[i]['Date'] === rettd ? dict['today'][di.c] : ret;
                let names = data[i]['workout_names'] !== undefined ?
                    data[i]['workout_names'] : "Default workout";
                let relative = data[i]['relative'] !== undefined ?
                    data[i]['relative'] : "N/A";
                let exs = [];
                let keys = Object.keys(data[i]);
                for (let y = 0; y < keys.length; y++) {
                    let t = keys[y];
                    if (t !== "Date" && t !== "workout_names" && t !== "relative") {
                        exs.push({
                            id: t,
                            sets: data[i][keys[y]],
                        });
                    }
                }
                workouts.push({
                    'date': ret,
                    'workouts': names,
                    'relative': relative,
                    'exercises': exs,
                });
            }
            this.state.workouts = workouts;
            this.state.loaded = true;
        }
        const last7 = await axios.post(`https://baluan.siranov.com/api/getWeek`, {
            'id': dataBus.user.id,
        });
        if (JSON.stringify(last7.data) !== "[]") {
            workouts = [];
            let data = last7.data;
            const td = new Date();
            let rettd = `${td.getDate()}/${td.getMonth() + 1}/${td.getFullYear()}`;
            for (var i = 0; i < data.length; i++) {
                let std = data[i]['Date'].split("/").map((e) => parseInt(e));
                let d = new Date(std[2], std[1] - 1, std[0]);
                let ret = `${dict['months'][di.c][std[1] - 1]} ${std[0]} (${dict['week'][di.c][d.getDay()]})`;
                ret = data[i]['Date'] === rettd ? dict['today'][di.c] : ret;
                let names = data[i]['workout_names'] !== undefined ?
                    data[i]['workout_names'] : "Default workout";
                let relative = data[i]['relative'] !== undefined ?
                    data[i]['relative'] : "N/A";
                let exs = [];
                let keys = Object.keys(data[i]);
                for (let y = 0; y < keys.length; y++) {
                    let t = keys[y];
                    if (t !== "Date" && t !== "workout_names" && t !== "relative") {
                        exs.push({
                            id: t,
                            sets: data[i][keys[y]],
                        });
                    }
                }
                workouts.push({
                    'date': ret,
                    'workouts': names,
                    'relative': relative,
                    'exercises': exs,
                });
            }
            this.setState({
                workouts: workouts,
                loaded: true,
            });
            dataBus.local7 = last7.data;
        }
    }

    render() {
        return <div class="fixed w-[100%] h-[100%] overflow-y-scroll">
            <div class="flex flex-col relative top-0 left-0 justify-start items-center">
                <div class="h-[70px] w-[10px]"></div>
                {this.state.workouts.length !== 0 ?
                    <WorkoutRenderer loaded={this.state.loaded} workouts={this.state.workouts} /> :
                    <div class="h-[100%] flex justify-center items-center">You haven't tracked any workouts yet!</div>}
                <div class="h-[50px] w-[100%] bg-[#6138D3] fixed left-0 top-0
         text-white text-[16px] flex flex-row justify-between items-center p-[10px]">
                    <BackButton />
                    <div>{dict['past-workouts'][di.c]}</div>
                    <div class="h-[20px] w-[20px]" />
                </div>
            </div>
        </div>;
    }
}

function WorkoutRenderer(props) {
    let navigate = useHistory();

    function goToWorkout(ind) {
        if (props.loaded) {
            const obj = props.workouts[ind];
            dataBus.workoutParams = {
                source: 'past',
                header: obj['date'],
                exercises: obj['exercises'],
            };
            navigate.push('/workout');
        }
    }


    let toRet = [];
    for (let b = 0; b < props.workouts.length; b++) {
        const i = b;
        let obj = props.workouts[i];
        toRet.push(
            <div onClick={() => { goToWorkout(b); }}
                key={i} class="min-h-[110px] w-[calc(100%-40px)] mb-[20px] rounded-[15px] bg-white relative">
                {JSON.stringify(obj) !== "{}" ? <>
                    <div class="w-[100%] min-h-[110px] flex flex-row">
                        <div class="w-[calc(100%-40px)] flex flex-col justify-start p-[20px]">
                            <div class="font-[20px] font-bold">{obj['date']}</div>
                            <div class="text-black/[0.4] font-[14px]">{`(${obj['workouts']})`}</div>
                            <div class="h-[20px]"></div>
                            <div class="flex flex-row items-center justify-start gap-[5px]">
                                <div>{`${dict['relative-improvement'][di.c]}: ${obj['relative']}${obj['relative'] !== "N/A" ? "%" : ""}`}</div>
                                {obj['relative'] !== "N/A" ?
                                    (obj['relative'] >= 100 ?
                                        <ArrowUpwardIcon sx={{ color: 'green' }} />
                                        : <ArrowDownwardIcon sx={{ color: 'red' }} />)
                                    : <></>}
                            </div>
                        </div>
                        <div class="w-[40px] flex items-center justify-center"><ChevronRightIcon sx={{ color: '#6138D3' }} /></div>
                    </div>
                </> : <></>}
            </div>);
    }
    return toRet.map((e) => e);
}


function BackButton() {
    let history = useHistory();
    function back() {
        history.push("/");
    }
    return <ChevronLeftIcon onClick={() => { back(); }} sx={{ color: 'white' }} />;
}

export default PastWorkouts;
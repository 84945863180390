import React from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import './utils.css';
import TimelineIcon from '@mui/icons-material/Timeline';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import dataBus from '../variables';
import { dict, di } from '../dict';
import wands from '../utils/waitAndScroll';
const axios = require('axios').default;
const tele = window.Telegram.WebApp;

let swipeTimer;


class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userLoaded: !(JSON.stringify(dataBus.user) === "{}"),
            swiping: false,
        }
        wands();
        this.getUser();
    }



    async getUser() {
        if (JSON.stringify(dataBus.user) === "{}") {
            try {
                await tele.ready();
                console.log(tele);
                if (JSON.stringify(tele) !== "{}") {
                    tele.expand();
                    tele.onEvent('viewportChanged', (e) => { console.log(e); });
                }
                let tUser = await tele.initDataUnsafe;
                if (JSON.stringify(tUser) === "{}") {
                    tUser = { user: { 'id': "438530097", 'first_name': "Ad Astra" } };
                }
                let response = await axios.post(`https://baluan.siranov.com/api/user`, {
                    id:`${tUser.user.id}`,
                });
                dataBus.user = response.data;
                console.log(dataBus.user);
                di.c = dataBus.user.language === undefined?"en":dataBus.user.language;
                this.setState({
                    userLoaded: true,
                });
            } catch (err) {
                console.log(err);
                prompt(err);
            }
        }
    }

    preventDef() {
        if (!this.state.swiping) {
            this.setState({
                swiping: true,
            });
        }
        swipeTimer = setInterval(() => { this.unSwipe(); }, 100);
    }

    unSwipe() {
        clearInterval(swipeTimer);
        if (this.state.swiping) {
            this.setState({
                swiping: false,
            });
        }
    }


    render() {
        return <div class="fixed w-[100%] h-[100%] overflow-y-scroll">
            <div class="pt-[20px] pb-[20px] relative">
            <div class="pl-[20px] text-[20px] font-bold">{dict['workout'][di.c]}</div>
            <div class="h-[10px]"></div>
            <div onTouchStart={(e) => { this.preventDef(); }}
                onScroll={(e) => { this.preventDef(); }} className="btn-scroll">
                <WorkoutButton pressable={this.state.userLoaded} />
                <div class="w-[20px] inline-block"></div>
                <ChallengeButton pressable={this.state.userLoaded} />
                <div class="w-[20px] inline-block"></div>
            </div>
            <div class="h-[10px]"></div>
            <div class="pl-[20px] text-[20px] font-bold">{dict['stats'][di.c]}</div>
            <div class="h-[10px]"></div>
            <div class="flex flex-col pl-[20px]  pr-[20px]">
                <div class="flex flex-row gap-[20px] justify-evenly items-center">
                    <div class="bg-white rounded-[15px] flex-1 h-[75px] p-[10px]">
                        {this.state.userLoaded ? <>
                            <div class="text-[20px] font-medium">
                                {(dataBus.user.streak !== undefined ?
                                    dataBus.user.streak : 0) + ` ${dict['days'][di.c]}`}
                            </div>
                            <div class="text-[16px]">{dict['current-streak'][di.c]}</div>
                        </> : <></>}
                    </div>
                    <div class="bg-white rounded-[15px] flex-1 h-[75px] p-[10px]">
                        {this.state.userLoaded ? <>
                            <div class="text-[20px] font-medium">
                                {dataBus.user.rep_gains !== undefined ?
                                    dataBus.user.rep_gains : "0%"}
                            </div>
                            <div class="text-[16px]">{dict['reps-gains'][di.c]}</div>
                        </> : <></>}
                    </div>
                </div>
                <div class="h-[20px]"></div>
                <FunctionalParent link="/pastWorkouts"
                    pressable={this.state.userLoaded} child={
                        <div class="w-[100%] h-[50px] p-[10px] rounded-[15px] bg-white flex flex-row items-center justify-between">
                            {this.state.userLoaded ? <>
                                <div class="text-[16px]">{dict['view-past-workouts'][di.c]}</div>
                                <TimelineIcon sx={{ color: '#6138D3' }} />
                            </> : <></>}
                        </div>
                    } />

                <div class="h-[20px]"></div>
                <FunctionalParent link="/leaderboards"
                    pressable={this.state.userLoaded} child={
                        <div class="w-[100%] h-[50px] p-[10px] rounded-[15px] bg-white flex flex-row items-center justify-between">
                            {this.state.userLoaded ? <>
                                <div class="text-[16px]">{dict['show-leaderboards'][di.c]}</div>
                                <EmojiEventsIcon sx={{ color: '#6138D3' }} />
                            </> : <></>}
                        </div>
                    } />
            </div>
            <div class="h-[20px]"></div>
            <div class="pl-[20px] text-[20px] font-bold">{dict['options'][di.c]}</div>
            <div class="pl-[20px] pr-[20px]">
                <div class="h-[10px]"></div>
                <FunctionalParent link="/edit"
                    pressable={this.state.userLoaded} child={
                        <div class="w-[100%] h-[50px] p-[10px] rounded-[15px] bg-white flex flex-row items-center justify-between">
                            {this.state.userLoaded ? <>
                                <div class="text-[16px]">{dict['edit-workout-plan'][di.c]}</div>
                                <EditIcon sx={{ color: '#6138D3' }} />
                            </> : <></>}
                        </div>
                    } />
                <div class="h-[20px]"></div>
                <FunctionalParent link="/myExercises"
                    pressable={this.state.userLoaded} child={
                        <div class="w-[100%] h-[50px] p-[10px] rounded-[15px] bg-white flex flex-row items-center justify-between">
                            {this.state.userLoaded ? <>
                                <div class="text-[16px]">{dict['add-new-exercise'][di.c]}</div>
                                <AddIcon sx={{ color: '#6138D3' }} />
                            </> : <></>}
                        </div>} />
                <div class="h-[20px]"></div>
                <FunctionalParent link="/settings"
                    pressable={this.state.userLoaded} child={
                        <div class="w-[100%] h-[50px] p-[10px] rounded-[15px] bg-white flex flex-row items-center justify-between">
                            {this.state.userLoaded ? <>
                                <div class="text-[16px]">{dict['settings'][di.c]}</div>
                                <SettingsIcon sx={{ color: '#6138D3' }} />
                            </> : <></>}
                        </div>} />
                <div class="h-[20px]"></div>
                {this.state.userLoaded ? <div class="flex justify-center text-black/[0.7] items-center text-center">
                    {`${dict['logged-in-as'][di.c]}${dataBus.user['display_name']}`}</div> : <></>}
                <div class="h-[20px]"></div>
            </div>
        </div >
        </div>
            ;
    }
}


function WorkoutButton(props) {
    let navigate = useHistory();
    function handleClick(link) {
        dataBus.workoutParams = { source: 'user' };
        navigate.push(link);
    }
    let d = new Date();
    return <div onClick={() => { if (props.pressable) { handleClick('/workout'); } }}
        class="w-[200px] inline-block h-[200px] overflow-hidden relative shadow-[0_4px_4px_0px_rgba(0,0,0,0.3)] bg-[#6138D3] rounded-[15px]">
        <img class="absolute object-cover h-[300px] right-[0px] bottom-[-100px]"
            alt="" src="https://storage.googleapis.com/baluan-6ea96.appspot.com/home%2Fworkout-home-pic.png"></img>
        <div class="absolute left-[10px] bottom-[10px] whitespace-normal w-[100px] text-start break-words font-medium font-white text-[18px] text-white">
            {props.pressable ? `${dict['week'][di.c][d.getDay()]} ${dict['workout'][di.c]}` : ""}</div>
    </div>
}

function FunctionalParent(props) {
    let navigate = useHistory();
    function handleClick(link) {
        navigate.push(link);
    }
    return <div onClick={() => { if (props.pressable) { handleClick(props.link); } }}>
        {props.child}
    </div>;
}

function ChallengeButton(props) {
    let navigate = useHistory();
    function handleClick(link) {
        navigate.push(link);
    }
    return <div onClick={() => { if (props.pressable) { handleClick('/challenge'); } }}
        class="w-[200px] inline-block h-[200px] overflow-hidden relative shadow-[0_4px_4px_0px_rgba(0,0,0,0.3)] bg-[#71D487] rounded-[15px]">
        <img class="absolute object-cover h-[250px] right-[10px] bottom-[-60px]"
            alt="" src="https://storage.googleapis.com/baluan-6ea96.appspot.com/home%2Fchallenge-home-pic.png"></img>
        <div class="absolute left-[10px] bottom-[10px] w-[100px] whitespace-normal font-medium font-white text-[18px] text-white">
            {props.pressable ? dict['challenge-friends'][di.c] : ""}</div>
    </div>;
}



export default Home;
import React from 'react';
import { useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import dataBus from '../variables';
import { dict, di } from '../dict';
const axios = require('axios').default;

class Leaderboards extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            leaders: {},
            selected: 0,
        };
        this.getLeaderboards();
    }

    async getLeaderboards() {
        if (JSON.stringify(dataBus.leaders) === "{}") {
            const resp = await axios.post(`https://baluan.siranov.com/api/getLeaders`, {});
            dataBus['leaders'] = resp.data;
            this.setState({
                leaders: dataBus['leaders'],
            });
        } else {
            this.state['leaders'] = dataBus.leaders;
        }
        console.log(this.state['leaders']);
    }

    changeTab(ind) {
        this.setState({
            selected: ind,
        });
    }

    render() {
        return <div class="fixed w-[100%] h-[100%] overflow-y-scroll">
            <div class="flex left-0 top-0 flex-col justify-start items-start relative">
                <div class="h-[50px]" />
                <div class="p-[20px] w-[100%] flex flex-col justify-start items-start">
                    <div class="text-[20px] font-medium">{dict['sort-by'][di.c]}</div>
                    <div class="h-[10px]" />
                    <div class="flex flex-wrap gap-[10px]">
                        <div onClick={() => { this.changeTab(0); }}
                            class={`h-[30px] pl-[10px]
                 pr-[10px] rounded-[15px] flex justify-center items-center
                 ${this.state.selected === 0 ? 'bg-[#6138D3] text-white' : 'bg-white text-black'}`}>{dict['consistency'][di.c]}</div>
                        <div onClick={() => { this.changeTab(1); }}
                            class={`h-[30px] pl-[10px]
                 pr-[10px] rounded-[15px] flex justify-center items-center
                 ${this.state.selected === 1 ? 'bg-[#6138D3] text-white' : 'bg-white text-black'}`}>{dict['reps-gains'][di.c]}</div>
                    </div>
                    <div class="h-[30px]" />
                    <div class="text-[20px] font-medium">{dict['top'][di.c] + " 100"}</div>
                    <div class="text-[16px] text-black/[0.7]">{dict['all-stats-are'][di.c]}</div>
                    <div class="h-[10px]" />
                    <RenderTop100 cur={this.state.selected
                    } leaders={this.state.leaders} />
                </div>
                <div class="h-[50px] fixed w-[100%] bg-[#6138D3] text-white text-[16px] flex flex-row justify-between items-center p-[10px]">
                    <BackButton />
                    <div>{dict['leaderboards'][di.c]}</div>
                    <div class="h-[20px] w-[20px]" />
                </div>
            </div>
        </div>;
    }
}


function RenderTop100(props) {
    let keys = Object.keys(props.leaders);
    let ret = [];
    if (keys.length !== 0) {
        let obj = props.leaders[keys[props.cur]];
        for (let b = 0; b < obj.length; b++) {
            const i = b;
            ret.push(<div key={i} class="w-[100%] mb-[10px]
             flex flex-row justify-between items-center text-[18px]">
                <div>{`${i + 1}. ` + obj[i]['name']}</div>
                <div>{obj[i]['value']}</div>
            </div>);
        }
    }
    return ret.map((e) => e);
}

function BackButton() {
    let history = useHistory();
    function back() {
        history.push("/");
    }
    return <ChevronLeftIcon onClick={() => { back(); }} sx={{ color: 'white' }} />;
}

export default Leaderboards;
import React from 'react';
import { useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { dict, di } from '../dict';

function Challenge() {
    return <div class="flex left-0 top-0 flex-col justify-center items-center relative w-[100%] h-[100%]">
        <div class="h-[50px] w-[100%] bg-[#6138D3] text-white text-[16px] flex flex-row justify-between items-center p-[10px]">
            <BackButton />
            <div>{dict['challenge-friends'][di.c]}</div>
            <div class="h-[20px] w-[20px]" />
        </div>
        <div class="flex fixed top-[50%]  flex-row justify-center items-center text-[20px]">{dict['coming-soon'][di.c]}</div>
    </div>;
}

function BackButton() {
    let history = useHistory();
    function back() {
        history.push("/");
    }
    return <ChevronLeftIcon onClick={() => { back(); }} sx={{ color: 'white' }} />;
}

export default Challenge;
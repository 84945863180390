import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useHistory } from 'react-router-dom';
import Exercise from '../widgets/exercise';
import Modal from "react-modal";
import SetsEdit from "../widgets/setsEdit";
import dataBus from "../variables";
import { dict, di } from '../dict';
import EditIcon from '@mui/icons-material/Edit';
const axios = require('axios').default;

const week = ['D', "M", "T", "W", "R", "F", "S"];


class Workout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isExerciseOpen: false,
            activeExercise: -1,
            exercises: [],
            titles: [],
            isReady: false,
        };
        this.getWorkouts();
    }

    getWorkouts() {
        let exercises = [];
        let titles = [];
        let amount = 0;
        if (dataBus.workoutParams.source === 'user') {
            let d = new Date();
            let curDay = week[d.getDay()];
            let workouts = dataBus.user.workouts;
            for (var i = 0; i < workouts.length; i++) {
                if (workouts[i]['repeat_days'].includes(curDay)) {
                    titles.push({
                        'index': amount,
                        'name': workouts[i]['name'] !== undefined ? workouts[i]['name'] : `Workout ${i + 1}`,
                    });
                    amount = amount + workouts[i]['workout_ids'].length;
                    for (var b = 0; b < workouts[i]['workout_ids'].length; b++) {
                        exercises.push({
                            'id': workouts[i]['workout_ids'][b],
                            'sets': [],
                        });
                    }
                }
            }
            if (JSON.stringify(dataBus.localSets) !== "{}") {
                const d = dataBus.localSets;
                let keys = Object.keys(d);
                for (let i = 0; i < keys.length; i++) {
                    for (let b = 0; b < exercises.length; b++) {
                        if (keys[i] === exercises[b]['id']) {
                            exercises[b]['sets'] = d[keys[i]];
                        }
                    }
                }
            }
        } else if (dataBus.workoutParams.source === 'past') {
            exercises = dataBus.workoutParams.exercises;
        }
        this.state.exercises = exercises;
        this.state.titles = titles;
        this.getExercises(exercises);
        if (dataBus.workoutParams.source === 'user') {
            this.getLogs();
        }
    }

    async getExercises(exercises) {
        let toget = [];
        let idsResponse;
        let promises = [];
        for (var i = 0; i < exercises.length; i++) {
            let includes = false;
            for (var b = 0; b < dataBus.exerciseDb.length; b++) {
                if (dataBus.exerciseDb[b]['id'] === exercises[i]['id']) {
                    includes = true;
                }
            }
            if (!includes) {
                toget.push(exercises[i]['id']);
            }
        }
        if (toget.length !== 0) {
            promises.push(axios.post(`https://baluan.siranov.com/api/dailyWorkout`, {
                workout_ids: toget,
            }).then((response) => {
                idsResponse = response;
                for (var i = 0; i < idsResponse.data.length; i++) {
                    dataBus.exerciseDb.push(idsResponse.data[i]);
                }
            }).catch((er) => { }));
        }
        if (promises.length !== 0) {
            await Promise.all(promises);
            this.setState({
                isReady: true,
            });
        } else {
            this.state.isReady = true;
        }
    }

    async getLogs() {
        let promises = [];
        let d = new Date();
        let todayResponse;
        todayResponse = await axios.post(`https://baluan.siranov.com/api/getLog`, {
            'id': dataBus.user['id'],
            'chat_id': dataBus.user['chat_id'],
            'Date': `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`,
        });
        if (JSON.stringify(todayResponse.data) !== "{}") {
            const d = todayResponse.data;
            let keys = Object.keys(d);
            let curEx = this.state.exercises;
            dataBus.localSets = structuredClone(d);
            for (let i = 0; i < keys.length; i++) {
                for (let b = 0; b < curEx.length; b++) {
                    if (keys[i] === curEx[b]['id']) {
                        curEx[b]['sets'] = d[keys[i]];
                    }
                }
            }
            this.setState({
                exercises: curEx,
            });
        }
    }


    async openExercise(ind) {
        this.setState({
            isExerciseOpen: true,
            activeExercise: ind,
        });
        await sleep(20);
        let scrollable = document.getElementById('workout-scroll');
        scrollable.scrollTo({ left: 0, top: ind * 110, behavior: 'smooth' });
    }

    closeExercise() {
        this.setState({
            activeExercise: -1,
            isExerciseOpen: false,
        });
    }

    async updateDb(sets) {
        if (this.state.activeExercise !== -1) {
            let curExercises = structuredClone(this.state.exercises);
            curExercises[this.state.activeExercise]['sets'] = sets;
            this.state.exercises = curExercises;
            let d = new Date();
            let log = {};
            for (var i = 0; i < curExercises.length; i++) {
                log[`${curExercises[i]['id']}`] = curExercises[i]['sets'];
            }
            log['Date'] = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
            let ret_names = [];
            for (var j = 0; j < dataBus.user.workouts.length; j++) {
                let w = dataBus.user.workouts[j];
                if (w['repeat_days'].includes(week[d.getDay()])) {
                    let workout_name = w['name'] !== undefined ? w['name'] : `Workout ${j + 1}`;
                    ret_names.push(workout_name);
                }
            }
            log['workout_names'] = ret_names;
            let body = {
                'id': dataBus.user['id'],
                'chat_id': dataBus.user['chat_id'],
                'Date': `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`,
                'log': log,
            };
            if (dataBus.localSets !== log) {
                await axios.post(`https://baluan.siranov.com/api/postLog`, body);
            }
            dataBus.localSets = structuredClone(log);
        }
    }

    changeEx(val) {
        let cur = this.state.activeExercise;
        this.setState({
            activeExercise: cur + val,
        });
        let scrollable = document.getElementById('workout-scroll');
        scrollable.scrollTo({ left: 0, top: (cur + val) * 110, behavior: 'smooth' });
    }

    curExerciseType() {
        let ret = "reps";
        if (this.state.isReady && this.state.activeExercise !== -1) {
            let ind = dataBus.exerciseDb.findIndex((e) => e['id']
                === this.state.exercises[this.state.activeExercise]['id']);
            if (ind !== -1) {
                ret = dataBus.exerciseDb[ind]['type'];
            }
        }
        return ret;
    }


    render() {
        let d = new Date();
        let height = this.state.activeExercise !== -1 ? "180px" : "100%";
        return <div id="workout-scroll" className="workout-scroll-css"
            class={`h-[${height}] w-[100%] overflow-y-scroll`}>
            <div class="h-[70px]" />
            <ExerciseRender titles={this.state.titles} isOpen={this.state.isExerciseOpen} isReady={this.state.isReady}
                exercises={this.state.exercises}
                openExercise={(i) => { this.openExercise(i); }} />
            <div class="h-[50px] left-0 top-0 bg-[#6138D3] fixed w-[100%] pl-[20px] pr-[20px] flex flex-row justify-between items-center">
                <BackButton />
                <div class="text-[16px] text-white">
                    {dataBus.workoutParams.source === "user" ?
                        `${dict['week'][di.c][d.getDay()]} ${dict['workout'][di.c]}` : dataBus.workoutParams.header}
                </div>
                <div class="w-[20px]"></div>
            </div>
            <Modal isOpen={this.state.isExerciseOpen}
                preventScroll={true}
                ariaHideApp={false}
                style={{
                    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.6)', overflow: 'scroll' },
                    content: { backgroundColor: '#E6E6E6', overflow: 'auto', overscrollBehavior: 'none' }
                }}
                className="w-[100%] outline-none m-0 p-0 border-0 h-[100%] bg-[#E6E6E6] fixed bottom-0 border-none"
                onRequestClose={() => { this.closeExercise(); }}
                contentLabel="">
                <SetsEdit
                    isReady={this.state.isReady}
                    type={this.curExerciseType()}
                    activeExercise={this.state.activeExercise}
                    isLast={this.state.activeExercise === this.state.exercises.length - 1}
                    exerciseId={this.state.activeExercise !== -1 ?
                        this.state.exercises[this.state.activeExercise]['id'] : []}
                    sets={this.state.activeExercise !== -1 ?
                        this.state.exercises[this.state.activeExercise]['sets'] : []}
                    changeEx={(val) => { this.changeEx(val); }}
                    closeEditor={() => { this.closeExercise(); }}
                    updateDb={(sets) => { this.updateDb(sets); }}
                />
            </Modal>
        </div>;
    }
}

function ExerciseRender(props) {
    var toRet = [];
    let history = useHistory();
    function toEdit() {
        history.push('/edit');
    }
    for (var b = 0; b < props.exercises.length; b++) {
        const i = b;
        const titleInd = props.titles.findIndex((e) => e['index'] === i);
        toRet.push(<div key={i}>
            {titleInd !== -1 ? <div class="w-[100%] pl-[25px] pr-[20px] flex flex-row items-center justify-between mb-[15px]">
                <div class="text-[16px] text-black/[0.5]">{props.titles[titleInd]['name']}</div>
                <div onClick={() => {toEdit();}} class="h-[30px] w-[30px]  flex justify-center items-center">
                    <EditIcon sx={{ fontSize: '20px', opacity: 0.3 }} /></div>
            </div> : <></>}
            <Exercise showButton={dataBus.workoutParams.source === "user"}
                isReady={props.isReady} sets={!props.isOpen ? props.exercises[b]['sets'] : []}
                id={`${props.exercises[b]['id']}`}
                onClick={() => {
                    if (props.isReady && dataBus.workoutParams.source === "user") { props.openExercise(i); }
                }} />
            <div class="h-[20px]" />
        </div>);
    }
    return toRet.map((e) => e);
}

function BackButton() {
    let history = useHistory();
    function back() {
        let link = dataBus.workoutParams.source === "user" ? "/" : "/pastWorkouts";
        history.push(link);
    }
    return <ChevronLeftIcon onClick={() => { back(); }} sx={{ color: 'white' }} />;
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default Workout;
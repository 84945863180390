import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './pages/home';
import Workout from './pages/workout';
import Challenge from './pages/challenge';
import Leaderboards from './pages/leaderboards';
import PastWorkouts from './pages/pastWorkouts';
import Edit from './pages/edit';
import AddExercise from './pages/addExercise';
import EditWorkout from './pages/editWorkout';
import MyExercises from './pages/myExercises';
import WorkoutPreview from './pages/workoutPreview';
import WorkoutLibrary from './pages/workoutLibrary';
import Settings from './pages/settings';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <div id="mainscroll" className="body">
    <Router>
      <Switch>
       <Route exact path="/"><Home /></Route> 
       <Route path="/workout"><Workout /></Route> 
       <Route path="/challenge"><Challenge/></Route>
       <Route path="/pastWorkouts"><PastWorkouts/></Route>
       <Route path="/leaderboards"><Leaderboards/></Route>
       <Route path="/edit"><Edit/></Route>
       <Route path="/editWorkout"><EditWorkout/></Route>
       <Route path="/addExercise"><AddExercise/></Route>
       <Route path="/workoutLibrary"><WorkoutLibrary/></Route>
       <Route path="/myExercises"><MyExercises/></Route>
       <Route path="/workoutPreview"><WorkoutPreview/></Route>
       <Route path="/settings"><Settings/></Route>
      </Switch>
    </Router>
  </div>
);


import React from 'react';
import { useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import dataBus from '../variables';
import { dict, di } from '../dict';
import wands from '../utils/waitAndScroll';
import AddIcon from '@mui/icons-material/Add';
const axios = require('axios').default;


const days = ['M', 'T', 'W', 'R', 'F', 'S', 'D'];
const goodColors = ['bg-[#00B490]/[0.2]'];



class Edit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'workouts': [],
            'library': [{}, {}, {}, {}, {}],
        }
        wands();
        this.setupWorkouts();
    }

    setupWorkouts() {
        let workouts = dataBus.user.workouts;
        let dispWorkouts = [];
        for (let i = 0; i < workouts.length; i++) {
            let obj = workouts[i];
            let name = '';
            name = obj['name'] !== undefined ? obj['name'] : `Workout ${i + 1}`;
            let dispDays = obj.repeat_days.map((e) => dict['semi-days'][di.c][days.findIndex((b) => b == e)]);
            dispDays = dispDays.join(", ");
            let image = obj['cover_image'] !== undefined ? obj['cover_image'] : "";
            dispWorkouts.push({
                'obj': obj,
                'image': image,
                'name': name,
                'numberOfExercises': obj.workout_ids.length,
                'days': dispDays,
            });
        }
        this.state.workouts = dispWorkouts;
        this.getMissingCovers();
        this.getLibraryFeatured();
    }

    async getMissingCovers() {
        let toAdd = [];
        let indecies = [];
        let disps = structuredClone(this.state.workouts);
        for (let i = 0; i < disps.length; i++) {
            if (disps[i]['image'] === "") {
                if (disps[i]['obj']['workout_ids'].length > 0) {
                    let first = disps[i]['obj']['workout_ids'][0];
                    let ind = dataBus.exerciseDb.findIndex((e) => e['id'] === first);
                    if (ind === -1) {
                        toAdd.push(first);
                        indecies.push(i);
                    } else {
                        disps[i]['image'] = dataBus.exerciseDb[ind]['image'];
                    }
                } else {
                    disps[i]['image'] = '';
                }
            }
        }
        this.state.workouts = disps;
        if (toAdd.length !== 0) {
            const response = await axios.post(`https://baluan.siranov.com/api/dailyWorkout`, {
                workout_ids: toAdd,
            });
            for (var i = 0; i < response.data.length; i++) {
                let dispIndex = indecies[i];
                disps[dispIndex]['image'] = response.data[i]['image'];
                dataBus.exerciseDb.push(response.data[i]);
            }
            this.setState({
                workouts: disps,
            });
        }

    }


    async getLibraryFeatured() {
        if (dataBus.libFeatured.length === 0) {
            const resp = await axios.post(`https://baluan.siranov.com/api/suggestedLibrary`, {});
            dataBus.libFeatured = resp.data;
            this.setState({
                'library': dataBus.libFeatured,
            });
        } else {
            this.state['library'] = dataBus.libFeatured;
        }
    }



    render() {
        return <div class="fixed w-[100%] h-[100%] overflow-y-scroll">
            <div class="flex left-0 top-0 flex-col justify-center items-center relative">
                <div class="h-[70px]"></div>
                <div class="flex w-[100%] flex-col flex-start items-start pl-[20px] text-start font-bold text-[20px]">{dict['my-workouts'][di.c]}</div>
                <RenderWorkouts workouts={this.state.workouts} />
                <div class="w-[100%] justify-between flex flex-row pl-[20px] pr-[20px]">
                    <div class="font-bold text-[20px]">{dict['library'][di.c]}</div>
                    <NavCover child={<div
                        class="">{dict['more->'][di.c]}</div>} />
                </div>
                <WorkoutLibrary library={this.state.library} />
                <div class="h-[50px] fixed w-[100%] top-0 left-0 bg-[#6138D3] text-white text-[16px] flex flex-row justify-between items-center p-[10px]">
                    <BackButton />
                    <div>{dict['edit-workout-plan'][di.c]}</div>
                    <div class="h-[20px] w-[20px]" />
                </div>
            </div>
        </div>
    }
}

function NavCover(props) {
    let navigate = useHistory();
    function goTo() {
        navigate.push('/workoutLibrary');
    }
    return <div onClick={() => { goTo(); }}>{props.child}</div>
}

function RenderWorkouts(props) {
    let navigate = useHistory();
    function toWorkout(i) {
        if (i !== -1) {
            dataBus.workoutParams = {
                'source': 'edit',
                'index': i,
                'exercises': dataBus.user.workouts[i].workout_ids,
                'object': dataBus.user.workouts[i],
                'name': props.workouts[i].name,
                'disp_days': props.workouts[i].days,
            };
        } else {
            dataBus.workoutParams = {
                'source': 'edit',
                'index': -1,
                'exercises': [],
                'object': { 'repeat_days': ['M'], 'workout_ids': [] },
                'name': `Workout ${props.workouts.length + 1}`,
                'disp_days': 'Mon',
            }
        }
        navigate.push('/editWorkout');
    }

    let ret = [];
    for (let b = 0; b < props.workouts.length; b++) {
        const i = b;
        const image = props.workouts[b]['image'];
        ret.push(<div key={i} onClick={() => { toWorkout(i); }}
            class={`relative w-[calc(50%-10px)] bg-white shadow-md rounded-[10px]`}>
            <div class="w-[100%] aspect-video rounded-tl-[10px] rounded-tr-[10px] bg-white">
                {image !== "" ? <img class="w-[100%] rounded-tl-[10px] rounded-tr-[10px] aspect-video object-cover absolute top-0 left-0" src={image}></img> : <></>}
                <div class="w-[100%] aspect-video bg-black/[0.3] rounded-tl-[10px] rounded-tr-[10px]  absolute top-0 left-0">
                </div>
            </div>
            <div class="h-[10px]"></div>
            <div class="w-[100%] p-[10px] flex flex-col">
                <div class=" text-[16px] text-black/[0.7]">{props.workouts[i]['numberOfExercises'] + ' ' + dict['exercises'][di.c]}</div>
                <div class="text-[16px] font-medium">{props.workouts[i]['name']}</div>
            </div>
        </div>);
    }
    ret.push(<div
        key={'custom'} class="relative flex items-center justify-center  min-h-[100px] w-[calc(50%-10px)]">
        <div onClick={() => { toWorkout(-1); }} class="w-[60px] h-[60px] p-[10px] rounded-[20px] shadow-sm bg-white flex flex-col items-center justify-center">
            <AddIcon sx={{ color: '#6138D3' }} />
        </div>
    </div>);
    return <div class="p-[20px] gap-[20px] flex flex-wrap w-[100%]">{ret.map((e) => e)}</div>
}

function WorkoutLibrary(props) {
    let history = useHistory();
    function openPreview(obj) {
        dataBus.workoutPreview = obj;
        dataBus.workoutParams = { 'from': 'edit' };
        history.push("/workoutPreview");
    }
    let ret = [];
    for (let b = 0; b < props.library.length; b++) {
        const i = b;
        const obj = props.library[b];
        let exs = obj['workout_ids'] !== undefined ? obj['workout_ids'].length + " " + dict['exercises'][di.c] : "";
        ret.push(<div onClick={() => { openPreview(obj); }}
            key={i} class="relative w-[calc(50%-10px)] bg-white shadow-md rounded-[10px]">
            <div class="w-[100%] aspect-video relative">
                {obj['cover_image'] !== undefined ? <>
                    <img class=" w-[100%] h-[100%] object-cover
                 rounded-tl-[10px] rounded-tr-[10px]" src={obj['cover_image']}></img>
                    <div class="h-[100%] absolute top-0 left-0 w-[100%] bg-black/[0.2] rounded-tl-[10px] rounded-tr-[10px]"></div>
                </> : <></>}
            </div>
            <div class='h-[10px]'></div>
            <div class="w-[100%] p-[10px] justify-start items-start flex flex-col">
                <div class="text-[16px] text-black/[0.7]">{exs}</div>
                <div class="text-[16px] font-medium">{obj['name']}</div>
            </div>
        </div>);
    }
    return <div class="p-[20px] gap-[20px] flex flex-wrap w-[100%]">{ret.map((e) => e)}</div>
}

function BackButton() {
    let history = useHistory();
    function back() {
        history.push("/");
    }
    return <ChevronLeftIcon onClick={() => { back(); }} sx={{ color: 'white' }} />;
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export default Edit;
import React from 'react';
import { useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Cropper from 'react-easy-crop'
import getCroppedImg from '../utils/cropImage';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from "react-modal";
import dataBus from '../variables';
import { di, dict } from '../dict';
const axios = require('axios').default;


let size = {};
let scrollTimer;
let cropInterval;
let addObj = { 'name': '', 'type': '', 'class': '' };
let tempBase = { 'small': "", 'big': "" };

class AddExercise extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            image: null,
            imageUrl: '',
            finalImageBig: '',
            finalImageSmall: '',
            toCrop: false,
            crop: { 'big': { x: 0, y: 0 }, 'small': { x: 0, y: 0 } },
            zoom: { 'big': 1, 'small': 1 },
            name: '',
            type: '',
            id: '',
            by: '',
            class: '',
            ready: false,
            added: false,
            uploading: false,
            picEdit: 0,
        }
    }


    async fileHandler(event) {
        let url = await URL.createObjectURL(event.target.files[0]);
        this.setState({
            image: event.target.files[0],
            toCrop: true,
            imageUrl: url,
        });
        const img = new Image();
        img.addEventListener("load", function () {
            size = { w: this.width, h: this.height };
        });
        img.src = url;
    }

    cropChange(e, n) {
        let cur = structuredClone(this.state.crop);
        cur[n === 0 ? 'big' : 'small'] = e;
        this.setState({
            crop: cur,
        });
    }

    zoomChange(e, n) {
        let cur = structuredClone(this.state.zoom);
        cur[n === 0 ? 'big' : 'small'] = e;
        this.setState({
            zoom: cur,
        });
    }

    checkChange(val, type) {
        if (type === 0 || type === 1 || type === 2) {
            addObj[type === 0 ? "name" : type === 1 ? 'type' : type === 2 ? 'class' : ''] = val;
        }
        let ready = false;
        if (addObj['name'] !== "" &&
            addObj['type'] !== "" &&
            addObj['class'] !== "" &&
            (this.state.picEdit === -1 &&
                this.state.finalImageBig !== '' &&
                this.state.finalImageSmall !== '')) {
            ready = true;
        }
        let id = addObj['name'];
        id = id.trim();
        id = addObj['name'].split(" ").join("-");
        id = id + "@" + dataBus.user.id;
        id = id.toLowerCase();
        addObj['id'] = id;
        addObj['by'] = dataBus.user.id;
        let toAdd = structuredClone(addObj);
        toAdd['ready'] = ready;
        this.setState(toAdd);
        clearInterval(scrollTimer);
        if (ready) {
            scrollTimer = setInterval(() => { this.scrollDown(); }, 1000);
        }
        if (addObj['name'] === "" ||
            addObj['type'] === "" ||
            addObj['class'] === "" ||
            this.state.picEdit !== -1) {
            clearInterval(scrollTimer);
            this.setState({
                ready: false,
            });
        }
    }

    async scrollDown() {
        clearInterval(scrollTimer);
        await sleep(100);
        console.log('scroll to the bottom');
    }


    updateCrop(e, n) {
        const sizeObj = {
            width: (e.width * size.w) / 100,
            height: (e.height * size.h) / 100,
            x: e.x * (size.w / 100),
            y: e.y * (size.h / 100),
        };
        clearInterval(cropInterval);
        cropInterval = setInterval(() => { this.getCrop(sizeObj, n) }, 200);
    }

    async getCrop(sizeObj, n) {
        clearInterval(cropInterval);
        let final = await getCroppedImg(this.state.imageUrl, sizeObj);
        console.log('cropped once');
        tempBase[n === 0 ? 'big' : 'small'] = final;
    }

    async pressedEdit(n) {
        let cur = this.state.picEdit;
        let toSet = -1;
        toSet = cur === n ? -1 : n;
        if (n === 0 && cur === 0) {
            toSet = 1;
        }
        let obj = { picEdit: toSet };
        obj[n === 0 ? 'finalImageBig' : 'finalImageSmall'] = tempBase[n === 0 ? "big" : "small"];
        this.setState(obj);
        await sleep(100);
        this.checkChange();
    }

    removeImage() {
        document.getElementById('file-in').value = null;
        this.setState({
            image: null,
            imageUrl: '',
            finalImageBig: '',
            finalImageSmall: '',
            toCrop: false,
            crop: { 'big': { x: 0, y: 0 }, 'small': { x: 0, y: 0 } },
            zoom: { 'big': 1, 'small': 1 },
            ready: false,
            picEdit: 0,
        });
    }

    async uploadExercise() {
        this.setState({
            uploading: true,
        });
        let bigFile = dataURLtoFile(this.state.finalImageBig, `big-${addObj['id']}.png`);
        let smallFile = dataURLtoFile(this.state.finalImageSmall, `small-${addObj['id']}.png`);
        let sendObj = structuredClone(addObj);
        var formData = new FormData();
        formData.append('preview', smallFile, smallFile.name);
        formData.append('image', bigFile, bigFile.name);
        for (var i = 0; i < Object.keys(sendObj).length; i++) {
            let key = Object.keys(sendObj)[i];
            let obj = structuredClone(sendObj[key]);
            formData.append(key, obj);
        }
        for (var entries in formData.entries()) {
            console.log(entries);
        }
        let resp = await axios.post(`https://baluan.siranov.com/api/addExercise`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        dataBus.myexs.push(resp.data);
        document.getElementById('file-in').value = null;
        this.setState({
            image: null,
            imageUrl: '',
            finalImageBig: '',
            finalImageSmall: '',
            toCrop: false,
            crop: { 'big': { x: 0, y: 0 }, 'small': { x: 0, y: 0 } },
            zoom: { 'big': 1, 'small': 1 },
            name: '',
            type: '',
            id: '',
            by: '',
            class: '',
            ready: false,
            added: true,
            picEdit: 0,
        });
        document.getElementById('add-name-inp').value = "";
        document.getElementById('add-type-inp').value = "";
        document.getElementById('add-class-inp').value = "";
        await sleep(1000);
        this.setState({
            added: false,
            uploading: false,
        });
    }

    render() {
        return <div onScroll={(e) => { console.log(e); }}
            class="flex flex-col overflow-y-scroll justify-start items-center relative">
            <div class="h-[50px]" />
            <div class="w-[100%] p-[20px] flex flex-col justify-start items-start"
                id='add-page-scroll'>
                <div class="font-medium text-[20px]">{dict['name'][di.c]}</div>
                <div class="h-[10px]" />
                <input id="add-name-inp"
                    onChange={(val) => { this.checkChange(val.target.value, 0); }}
                    placeholder={dict['enter-exercise'][di.c]} class=" pl-[10px] outline-none border-none w-[100%] h-[50px] bg-white rounded-[15px]"></input>
                <div class="h-[20px]" />
                <div class="font-medium text-[20px]">{dict['type'][di.c]}</div>
                <div class="h-[10px]" />
                <select id="add-type-inp"
                    onChange={(val) => { this.checkChange(val.target.value, 1); }}
                    class={`outline-none pl-[10px] ${this.state.type == "" ? "text-black/[0.5]" : "text-black"} 
                    font-regular border-none w-[100%] h-[50px] bg-white rounded-[15px]`}>
                    <option value="" disabled selected>{dict['select-type'][di.c]}</option>
                    <option value="reps">{dict['reps'][di.c]}</option>
                    <option value="reps_sides">{dict['reps_sides'][di.c]}</option>
                    <option value="hold">{dict['hold'][di.c]}</option>
                    <option value="hold_sides">{dict['hold_sides'][di.c]}</option>
                    <option value="distance">{dict['distance'][di.c]}</option>
                </select>
                <div class="h-[20px]" />
                <div class="font-medium text-[20px]">{dict['class'][di.c]}</div>
                <div class="h-[10px]" />
                <select id="add-class-inp"
                    onChange={(val) => { this.checkChange(val.target.value, 2); }}
                    class={`outline-none pl-[10px] ${this.state.class == "" ? "text-black/[0.5]" : "text-black"} 
                    font-regular border-none w-[100%] h-[50px] bg-white rounded-[15px]`}>
                    <option value="" disabled selected>{dict['select-class'][di.c]}</option>
                    <option value="stretch">{dict['stretch'][di.c]}</option>
                    <option value="cardio">{dict['cardio'][di.c]}</option>
                    <option value="push">{dict['push'][di.c]}</option>
                    <option value="pull">{dict['pull'][di.c]}</option>
                </select>
                <div class="h-[20px]" />
                <div class="w-[100%] flex flex-row justify-between items-center">
                    <div class="w-[calc(100%-30px)]flex flex-col items-start">
                        <div class="font-medium text-[20px]">{dict['image'][di.c]}</div>
                        <div>{dict['use-png'][di.c]}</div>
                    </div>
                    {this.state.imageUrl !== "" ?
                        <div onClick={() => { this.removeImage(); }}
                            class="w-[30px] self-center text-end">
                            <DeleteIcon sx={{ color: 'red', opacity: 0.6 }} />
                        </div> : <></>}
                </div>
                <div class="h-[20px]" />
                {this.state.imageUrl !== "" ?
                    <div class="w-[100%] flex flex-col justify-start items-center">
                        <div class="flex flex-row w-[100%] justify-between">
                            <div class="text-[16px] text-black/[0.7] self-start">{dict['full-size-crop'][di.c]}</div>
                            <div onClick={() => { this.pressedEdit(0); }}
                            >{this.state.picEdit === 0 ? dict['done-small'][di.c] : dict['edit-small'][di.c]}</div>
                        </div>
                        <div class="h-[10px]" />
                        <div class="w-[100%] aspect-video relative">
                            <div class="w-[100%] h-[100%] absolute rounded-[10px] bg-blue-100">
                                {this.state.picEdit === 0 &&
                                    this.state.imageUrl !== "" ? <Cropper
                                    image={this.state.imageUrl}
                                    minZoom={0.5}
                                    restrictPosition={false}
                                    crop={this.state.crop['big']}
                                    zoom={this.state.zoom['big']}
                                    aspect={16 / 9}
                                    onCropChange={(e) => { this.cropChange(e, 0); }}
                                    onZoomChange={(e) => { this.zoomChange(e, 0); }}
                                    onCropComplete={(e) => { this.updateCrop(e, 0); }}
                                    objectFit="horizontal-cover"
                                /> : <img class="h-[100%] rounded-[10px] w-[100%] object-cover" src={this.state.finalImageBig}></img>}
                            </div>
                        </div>
                        <div class="h-[20px]" />
                        <div class="flex flex-row w-[100%] justify-between">
                            <div class="text-[16px] text-black/[0.7] self-start">{dict['square-size-crop'][di.c]}</div>
                            <div onClick={() => { this.pressedEdit(1); }}
                            >{this.state.picEdit === 1 ? dict['done-small'][di.c] : dict['edit-small'][di.c]}</div>
                        </div>
                        <div class="h-[10px]" />
                        <div class="w-[60%] aspect-square relative">
                            <div class="w-[100%] h-[100%] flex items-center absolute rounded-[10px]  bg-blue-100">
                                {this.state.picEdit === 1 &&
                                    this.state.imageUrl !== "" ? <Cropper
                                    image={this.state.imageUrl}
                                    minZoom={0.5}
                                    restrictPosition={false}
                                    crop={this.state.crop['small']}
                                    zoom={this.state.zoom['small']}
                                    aspect={1}
                                    onCropChange={(e) => { this.cropChange(e, 1); }}
                                    onZoomChange={(e) => { this.zoomChange(e, 1); }}
                                    onCropComplete={(e) => { this.updateCrop(e, 1); }}
                                    objectFit="horizontal-cover"
                                /> : <img class="aspect-square object-cover rounded-[10px]" src={this.state.finalImageSmall === "" ? this.state.imageUrl :
                                    this.state.finalImageSmall}></img>}
                            </div>
                        </div>
                        <div class="h-[20px]" />
                        {this.state.ready ?
                            <>
                                <div class="text-[16px] text-black/[0.7] self-start">{dict['final-preview'][di.c]}</div>
                                <div class="h-[10px]" />
                                <div class="w-[100%] h-[90px] p-[15px] rounded-[15px] bg-white flex flex-row justify-start items-center ">
                                    <img src={this.state.finalImageSmall === "" ? this.state.imageUrl : this.state.finalImageSmall} class="h-[60px] w-[60px] object-cover bg-blue-100 rounded-[15px]">
                                    </img>
                                    <div class="w-[15px]"></div>
                                    <div class="font-medium">{this.state.name}</div>
                                </div> </> : <></>}
                        <div class="h-[20px]" />
                    </div>
                    : <></>}
                <input
                    id="file-in"
                    type="file"
                    onChange={(event) => { this.fileHandler(event); }}
                    style={{ display: 'none' }}
                />
                {this.state.imageUrl === "" ? <div onClick={() => { document.getElementById('file-in').click(); }}
                    class="outline-none border-none w-[100%] h-[50px]
             flex items-center justify-center bg-white rounded-[15px]">
                    {dict['add-image'][di.c]+' +'}
                </div> : <></>}
                {this.state.ready ? <div onClick={() => { this.uploadExercise(); }}
                    class="w-[100%] h-[50px] flex items-center justify-center shadow-md
                 bg-[#6138D3] rounded-[15px] text-white">{dict['upload'][di.c]}</div> : <></>}
            </div>
            <Modal isOpen={this.state.uploading}
                ariaHideApp={false}
                style={{
                    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.6)', overflow: 'scroll' },
                    content: { backgroundColor: '#E6E6E6', overflow: 'auto', overscrollBehavior: 'none' }
                }}
                preventScroll={true}
                className="w-[calc(100%-40px)] outline-none m-0 p-0 border-0 h-[200px] rounded-[20px] bg-[#E6E6E6] fixed bottom-[calc(50%-100px)] left-[20px] border-none"
                onRequestClose={() => { }}
                contentLabel="">
                {!this.state.added ? <div class="w-[100%] h-[100%] flex justify-center items-center">{dict['uploading-exercise'][di.c]}</div> :
                    <div class="h-[100%] w-[100%] rounded-[20px] 
                    bg-[#6138D3] flex justify-center items-center 
                    text-white text-[20px] font-medium">
                        {dict['added-new-exercise'][di.c]}</div>}
            </Modal>
            <div class="h-[50px] w-[100%] fixed left-0 top-0 bg-[#6138D3]
             text-white text-[16px] flex flex-row justify-between items-center p-[10px]">
                <BackButton />
                <div>{dict['add-new-exercise'][di.c]}</div>
                <div class="h-[20px] w-[20px]" />
            </div>
        </div>;
    }
}

function BackButton() {
    let history = useHistory();
    function back() {
        history.push("/myExercises");
    }
    return <ChevronLeftIcon onClick={() => { back(); }} sx={{ color: 'white' }} />;
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export default AddExercise;
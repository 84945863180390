import React from 'react';
import { useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import dataBus from '../variables';
import { di, dict } from '../dict';
const axios = require('axios').default;

class WorkoutLibrary extends React.Component {

    constructor(props) {
        super(props);
        this.state = ({
            workouts: [{}, {}, {}, {}, {}],
        });
        this.getWorkouts();
    }

    async getWorkouts() {
        if (dataBus.libFull.length === 0) {
            const resp = await axios.post(`https://baluan.siranov.com/api/fullLibrary`, {});
            this.setState({
                workouts: resp.data,
            });
            dataBus.libFull = structuredClone(resp.data);
        } else {
            this.state = {
                workouts: dataBus.libFull, 
            };
        }
    }

    render() {
        return <div class="h-[100%] w-[100%] relative flex flex-col justify-start items-start">
            <div class="h-[50px]" />
            <WorkoutLibraryBig library={this.state.workouts} />
            <AppBar />
        </div>;
    }

}

function WorkoutLibraryBig(props) {
    let history = useHistory();
    function openPreview(obj) {
        dataBus.workoutPreview = obj;
        dataBus.workoutParams = { 'from': 'workoutLibrary' };
        history.push("/workoutPreview");
    }
    let ret = [];
    for (let b = 0; b < props.library.length; b++) {
        const i = b;
        const obj = props.library[b];
        let exs = obj['workout_ids'] !== undefined ? obj['workout_ids'].length + ` ${dict['exercises'][di.c]}` : "";
        ret.push(<div onClick={() => { openPreview(obj); }}
            key={i} class="relative w-[calc(50%-10px)] bg-white shadow-md rounded-[10px]">
            <div class="w-[100%] aspect-video relative">
                {obj['cover_image'] !== undefined ? <>
                    <img class=" w-[100%] h-[100%] object-cover
                 rounded-tl-[10px] rounded-tr-[10px]" src={obj['cover_image']}></img>
                    <div class="h-[100%] absolute top-0 left-0 w-[100%] bg-black/[0.2] rounded-tl-[10px] rounded-tr-[10px]"></div>
                </> : <></>}
            </div>
            <div class='h-[10px]'></div>
            <div class="w-[100%] p-[10px] justify-start items-start flex flex-col">
                <div class="text-[16px] text-black/[0.7]">{exs}</div>
                <div class="text-[16px] font-medium">{obj['name']}</div>
            </div>
        </div>);
    }
    return <div class="p-[20px] gap-[20px] flex flex-wrap w-[100%]">{ret.map((e) => e)}</div>
}


function AppBar() {
    let history = useHistory();
    function back() {
        history.push("/edit");
    }
    return <div class="w-[100%] h-[50px] fixed top-0
     left-0 flex items-center pl-[10px] text-center pr-[10px] text-white justify-between bg-[#6138D3]">
        <ChevronLeftIcon onClick={() => { back(); }} sx={{ color: 'white' }} />
        <div>{dict['library'][di.c]}</div>
        <div class="w-[30px] " />
    </div>;
}

export default WorkoutLibrary;
let dataBus = {
    user: {},
    exerciseDb: [],
    localSets: {},
    workoutParams: {},
    local7: [],
    featured: [],
    myexs: 'null',
    leaders: {},
    workoutPreview: {}, 
    libFeatured: [],
    libFull: [],
};

let color = '#6138D3';

export default dataBus;
import React from 'react';
import { useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import dataBus from '../variables';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { di, dict } from '../dict';
const axios = require('axios').default;


class MyExercises extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            editing: false,
            myExercises: [{ 'name': '', 'image': '' }, { 'name': '', 'image': '' }, { 'name': '', 'image': '' }],
        };
        this.getMyExercises();
    }

    async getMyExercises() {
        if (dataBus.myexs === "null") {
            const myExs = await axios.post(`https://baluan.siranov.com/api/exercisesByUser`, {
                user_id: dataBus.user.id,
            });
            dataBus.myexs = structuredClone(myExs.data);
            this.setState({
                myExercises: dataBus.myexs,
                loaded: true,
            });
        } else {
            let exs = structuredClone(dataBus.myexs);
            this.state['myExercises'] = exs;
            this.state['loaded'] = true;
            if (this.componentDidMount) {
                this.setState({
                    myExercises: exs,
                    loaded: true,
                });
            }
        }
    }

    delete(i){
        let cur = this.state.myExercises;
        axios.post(`https://baluan.siranov.com/api/removeExercise`, {
                id: cur[i]['id'],
        });
        cur.splice(i, 1);
        dataBus.myexs = cur;
        this.setState({
            myExercises: cur, 
        });
    }

    render() {
        let counter = 0;
        return <div class="h-[100%] w-[100%] p-[20px] fixed overflow-y-scroll">
            <div class="h-[50px]" />
            <div class="w-[100%] h-[30px] flex flex-row justify-between items-start">
                {this.state.loaded && this.state.myExercises.length!==0? <>
                    <div>{this.state.myExercises.length + " " + dict['exercises'][di.c]}</div>
                    <div class="text-[#6138D3]" onClick={() => {
                        let cur = this.state.editing;
                        this.setState({
                            editing: !cur,
                        })
                    }}>{this.state.editing ? dict['done-small'][di.c] : dict['edit-small'][di.c]}</div></> : ''}
            </div>
            <div class="h-[20px]" />
            {this.state.myExercises.map((e) => {
                const i = counter;
                counter++;
                return (
                    <div key={i} class="w-[100%] h-[90px] bg-white
                     rounded-[15px] p-[15px] mb-[20px] flex flex-row justify-start items-center">
                        <div class="w-[calc(100%-30px)] flex flex-row justify-start items-center">
                            <div class="w-[60px] h-[60px] bg-blue-100 rounded-[15px]">
                                {e['preview'] !== undefined ? <img src={e['preview']} class="h-[100%]
                             w-[100%] object-cover rounded-[15px]"></img> : <></>}
                            </div>
                            <div class="w-[15px]" />
                            <div class="font-medium text-start">{e['name']}</div>
                        </div>
                        {this.state.editing ? <DeleteIcon
                            onClick={() => {this.delete(i);}} sx={{color: 'black', opacity: 0.5}}/> : <></>}
                    </div>
                );
            })}
            {this.state.myExercises.length === 0 ?
                <div class="w-[100%] h-[100%] text-[18px] flex flex-col text-center justify-center items-center">
                    <div>{dict['you-havent-created'][di.c]}</div>
                    <div class="h-[10px]" />
                    <div>{dict['press-plus'][di.c]}</div>
                </div>
                : <></>}
            <AppBar />
        </div>
    }

}


function AppBar(props) {
    let history = useHistory();
    function back() {
        history.push("/");
    }
    function add() {
        history.push("/addExercise");
    }
    return <div class="w-[100%] h-[50px] fixed top-0
     left-0 flex items-center pl-[10px] text-center pr-[10px] text-white justify-between bg-[#6138D3]">
        <ChevronLeftIcon onClick={() => { back(); }} sx={{ color: 'white' }} />
        <div>{dict['my-exercises'][di.c]}</div>
        <AddIcon onClick={() => { add(); }} sx={{ color: 'white' }} />
    </div>;
}


export default MyExercises;
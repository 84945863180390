export const dict = {
    'workout': {
        kz: "Жаттығу",
        en: "Workout"
    },
    'week': {
        kz: ["Жексенбі", 'Дүйсенбі', 'Сейсенбі', 'Сәрсенбі', 'Бейсенбі', 'Жұма', 'Сенбі'],
        en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday',],
    }, 
    'challenge-friends': {
        kz: 'Достармен жарысу', 
        en: 'Challenge friends',  
    }, 
    'stats': {
        kz: 'Статистика',
        en: 'Stats', 
    },
    'days': {
        kz: 'Күн', 
        en: 'Days', 
    },
    'current-streak': {
        kz: 'Тоқтаусыз', 
        en: 'Current Streak'
    }, 
    'reps-gains': {
        kz: 'Өсім', 
        en: 'Reps gains', 
    }, 
    'view-past-workouts' : {
        kz: 'Өткен жаттығуларды көрсету', 
        en: 'View past workouts', 
    }, 
    'show-leaderboards': {
        kz: 'Жетекшілерді көрсету', 
        en: 'Show Leaderboards', 
    }, 
    'options': {
        kz: 'Қосымша', 
        en: 'Options', 
    }, 
    'edit-workout-plan': {
        kz: 'Жаттығу тізімің өзергту', 
        en: 'Change workout plan', 
    }, 
    'add-new-exercise': {
        kz: 'Жаңа жаттығу қосу', 
        en: 'Add new exercise', 
    }, 
    'logged-in-as': {
        kz: 'Тіркілген: ', 
        en: 'Logged in as '
    }, 
    'set': {
        kz: 'Қайталау', 
        en: 'Set'
    }, 
    'add-set': {
        kz: 'Қайталау қосу', 
        en: 'Add set', 
    }, 
    'delete': {
        kz: 'жою', 
        en: 'delete'
    }, 
    'coming-soon': {
        kz: 'Жақында жасалады!', 
        en: 'Coming soon!'
    }, 
    'past-workouts': {
        kz: 'Өткен жаттығулар', 
        en: 'Past Workouts', 
    }, 
    'today': {
        kz: 'Бүгін', 
        en: 'Today', 
    }, 
    'months': {
        kz: ['Қантар,', 'Ақпан, ', 'Наурыз, ', 'Сәуір, ', 'Мамыр, ', 'Маусым,', 'Шілде,', 'Тамыз,', 'Қыркүйек,', 'Қазан,', 'Қараша,', 'Желтоқсан,'],
        en: ['January', 'February', 'March', "April", "May", "June", "July", 'August', "September", "November", "December"],
    }, 
    'relative-improvement': {
        kz: 'Салыстырмалы Өсім', 
        en: 'Relative Improvement', 
    },
    'leaderboards': {
        kz: 'Жетекшілер', 
        en: 'Leaderboards', 
    }, 
    'sort-by': {
        kz: 'Сорттау',
        en: 'Sort By',  
    },
    'consistency': {
        kz: 'Жүйелілік', 
        en: 'Consistency', 
    },
    'top': {
        kz: 'Үздік', 
        en: 'Top', 
    },
    'all-stats-are': {
        kz: 'Барлық статистика осы аптадан', 
        en: 'All stats are based on this week', 
    },
    'my-workouts': {
        kz: 'Менің жаттығуларым', 
        en: 'My workouts', 
    },
    'exercises': {
        kz: 'Жаттығу', 
        en: 'Exercises', 
    }, 
    'new-workout': {
        kz: 'Жаңа жаттығу',
        en: 'New workout', 
    },
    'library': {
        kz: 'Жаттығулар қоры',
        en: 'Library',  
    },
    'more->': {
        kz: 'Көбірек >', 
        en: 'More >', 
    },
    'edit-workout': {
        kz: 'Өзгерту', 
        en: 'Edit workout', 
    },  
    'name': {
        kz: 'Аты', 
        en: 'Name', 
    },
    'days-word': {
        kz: 'Күндер', 
        en: 'Days', 
    },
    'exercises-word': {
        kz: 'Жаттығулар', 
        en: 'Exercises', 
    },
    'add-exercise': {
        kz: 'Жаттығу Қосу', 
        en: 'Add Exercise'
    },
    'delete-this': {
        kz: 'Осы жоспарды жою', 
        en: 'delete this workout',
    },
    'cancel': {
        kz: 'Болдырмау', 
        en: 'Cancel', 
    }, 
    'delete-big': {
        kz: 'Жою', 
        en: 'Delete', 
    },
    'are-you-sure': {
        kz: 'Осы жоспарды жоюңызды растаныз.', 
        en: 'Are you sure you want to delete this workout?', 
    },
    'semi-days': {
        kz: ['Дүй', 'Cей', 'Сәр', 'Бей', 'Жұм', 'Сен', 'Жек'],
        en: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    },
    'display-week': {
        kz: ['Дүйсенбі', 'Сейсенбі', 'Сәрсенбі', 'Бейсенбі', 'Жұма', 'Сенбі', 'Жексенбі'], 
        en: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    },
    'edit-days': {
        kz: 'Кундерді озгерту',
        en: 'Edit days', 
    },
    'save': {
        kz: 'Сақтау', 
        en: 'Save'
    },
    'edit-name': {
        kz: 'Атын өзгерту', 
        en: 'Edit name', 
    },
    'name-of-the': {
        kz: 'Жоспардын аты...', 
        en: 'Name of the workout...'
    }, 
    'enter-exercise': {
        kz: 'Жаттығу атын жазыңыз...', 
        en: 'Enter exercise name...', 
    }, 
    'search': {
        kz: 'Іздеу',
        en: 'Search',  
    }, 
    'featured': {
        kz: 'Таңдалған',
        en: 'Featured',  
    },
    'my-exercises': {
        kz: 'Менің жаттығуларым', 
        en: 'My exercises', 
    },
    'added': {
        kz: 'Қосылды', 
        en: 'Added', 
    },
    'add': {
        kz: 'Жоспарға қосу:', 
        en: 'Add', 
    },
    'cart': {
        kz: 'Жиын', 
        en: 'Cart'
    },
    'clear': {
        kz: 'Тазалау', 
        en: 'Clear', 
    },
    'search-results': {
        kz: 'Іздеу нәтижесі', 
        en: 'Search Results'
    },
    'edit-small': {
        kz: 'өзгерту', 
        en: 'edit', 
    },
    'done-small': {
        kz: 'болды', 
        en: 'done', 
    },
    'you-havent-created': {
        kz: 'Сіз әлі жаңа жаттығуладры қоспадыңыз', 
        en: "You haven't created exercises yet", 
    },
    'press-plus': {
        kz: 'Бастау үшін, "+" басыңыз', 
        en: 'Press "+" to start', 
    }, 
    'type': {
        kz: 'Түрі', 
        en: 'Type', 
    },
    'class': {
        kz: 'Классификациясы', 
        en: 'Class', 
    },
    'image': {
        kz: 'Бейне', 
        en: 'Image',
    }, 
    'use-png': {
        kz: 'Үздік нәтиже ушін .png қолдану керек', 
        en: 'use .png for best results', 
    },
    'add-image': {
        kz: 'Бейне Қосу', 
        en: 'Add Image', 
    }, 
    'reps': {
        kz: 'Ретпен Қайталау', 
        en: 'Reps', 
    },
    'reps_sides': {
        kz: 'Ретпен Қайталау, сол/оң', 
        en: 'Reps, left/right'
    },
    'hold': {
        kz: 'Уақытқа ұстау', 
        en: 'Hold', 
    },
    'hold_sides': {
        kz: 'Уақытқа ұстау, сол/оң', 
        en: 'Hold, left/right'
    },
    'distance': {
        kz: 'Кашықтық', 
        en: 'Distance', 
    },
    'stretch': {
        kz: 'Созылу', 
        en: 'Stretch', 
    }, 
    'cardio': {
        kz: 'Кардио', 
        en: 'Cardio', 
    }, 
    'push': {
        kz: 'Итеру', 
        en: 'Push', 
    },
    'pull': {
        kz: 'Тарту', 
        en: 'Pull', 
    }, 
    'select-type': {
        kz: 'Түрің Таңдаңыз...',
        en: 'Select Type...',  
    },
    'select-class': {
        kz: 'Классификацияны Таңдаңыз...',
        en: 'Select Class...',  
    }, 
    'full-size-crop': {
        kz: 'Толық мөлшер (үлкейту/қию)',
        en: 'Full-size (crop/resize)',
    },
    'square-size-crop': {
        kz: '1:1 (үлкейту/қию)', 
        en: '1:1 (crop/resize)'
    },
    'final-preview': {
        kz: 'Соңғы нәтиже', 
        en: 'Final Preview', 
    },
    'upload': {
        kz: 'Жүктеу', 
        en: 'Upload', 
    },
    'uploading-exercise': {
        kz: 'Жаттығу жүктеліп жатыр', 
        en: 'Uploading exercise...', 
    },
    'added-new-exercise': {
        kz: 'Жаңа жаттығу қосылды!', 
        en: 'Added new exercise!', 
    },
    'updating-information': {
        kz: 'Жаңартылып жатыр...', 
        en: 'Updating information...'
    }, 
    'save-changes': {
        kz: 'Өзгерістерді сақтау', 
        en: 'Save Changes', 
    },
    'add-to-plan': {
        kz: 'Жоспарға қосу', 
        en: 'Add to my plan', 
    },
    'already-added': {
        kz: 'Жоспарға косылған', 
        en: 'Already in your plan', 
    },
    'settings': {
        kz: 'Баптау', 
        en: 'Settings', 
    },
    'language': {
        kz: 'Тiл', 
        en: 'Language', 
    },
};

export let di = {c:'en'};


import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import dataBus from '../variables';
import CloseIcon from '@mui/icons-material/Close';
import { di, dict } from '../dict';
const axios = require('axios').default;

let searchVal = '';

class Search extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            exercises: [{ 'name': '', 'image': '' }, { 'name': '', 'image': '' }, { 'name': '', 'image': '' }],
            cart: [],
            tab: 0,
        };
        this.getFeatured();
    }

    async getFeatured() {
        if (JSON.stringify(dataBus.featured) === "[]") {
            const featResponse = await axios.post(`https://baluan.siranov.com/api/suggestedExercises`, {});
            let exs = structuredClone(featResponse.data);
            dataBus.featured = structuredClone(featResponse.data);
            this.setState({
                exercises: exs,
            });
        } else {
            let exs = structuredClone(dataBus.featured);
            this.state['exercises'] = exs;
            if (this.componentDidMount) {
                this.setState({
                    exercises: exs,
                });
            }
        }
    }

    async getMyExercises() {
        if (dataBus.myexs === "null") {
            this.setState({
                exercises: [{ 'name': '', 'image': '' }, { 'name': '', 'image': '' }, { 'name': '', 'image': '' }],
            });
            const myExs = await axios.post(`https://baluan.siranov.com/api/exercisesByUser`, {
                user_id: dataBus.user.id,
            });
            dataBus.myexs = structuredClone(myExs.data);
            this.setState({
                exercises: dataBus.myexs,
            });
        } else {
            let exs = structuredClone(dataBus.myexs);
            this.state['exercises'] = exs;
            if (this.componentDidMount) {
                this.setState({
                    exercises: exs,
                });
            }
        }
    }

    showCartExercises() {
        let exs = structuredClone(this.state.cart);
        this.setState({
            exercises: exs,
        });
    }

    switchTab(i) {
        if (i === 0) {
            this.getFeatured();
        } else if (i === 1) {
            this.getMyExercises();
        } else if (i === 3) {
            this.showCartExercises();
        }
        document.getElementById('search-inp').value = '';
        this.setState({
            tab: i,
        });
    }

    async search() {
        this.setState({
            'tab': 2,
            'exercises': [{ 'name': '', 'image': '' }, { 'name': '', 'image': '' }, { 'name': '', 'image': '' }],
        });
        const searchQuery = await axios.post(`https://baluan.siranov.com/api/exerciseSearch`, {
            query: searchVal,
        });
        let exs = structuredClone(searchQuery.data);
        this.setState({
            'exercises': exs,
        });
    }

    clearSearch() {
        this.setState({
            'tab': 0,
        });
        document.getElementById('search-inp').value = '';
        this.getFeatured();
    }

    addToCart(obj) {
        let exs = structuredClone(this.state.cart);
        exs.push(obj);
        this.setState({
            cart: exs,
        });
        this.adjustForAction(true);
    }

    removeFromCart(obj) {
        let exs = structuredClone(this.state.cart);
        let ind = exs.findIndex((e) => e['id'] === obj['id']);
        if (ind !== -1) {
            exs.splice(ind, 1);
        }
        this.setState({
            cart: exs,
        });
        if (this.state.tab === 3) {
            this.setState({
                exercises: exs,
            });
        }
        this.adjustForAction(false);
    }

    adjustForAction(pos){
        let scr = document.getElementById('search-scroll');
        let cur = scr.scrollTop;
        scr.scroll({top: cur+(pos?80:-80), behavior: 'smooth'});
    }

    render() {
        return <div id='search-scroll' class="h-[100%] w-[100%] overflow-y-scroll absolute left-0 top-0">
            <div class="w-[100%] relative p-[20px] flex flex-col justify-start items-start">
                <div class="w-[100%] flex flex-row justify-between items-center">
                    <div class="text-[24px] font-bold">{dict['add-exercise'][di.c]}</div>
                    <CloseIcon onClick={() => { this.props.close(); }} />
                </div>
                <div class='h-[20px]'></div>
                <div class="w-[100%] flex flex-row justify-between items-center">
                    <input id='search-inp' placeholder={dict['enter-exercise'][di.c]}
                        class="h-[50px] w-[calc(100%-100px)] outline-none pl-[10px] bg-white rounded-[10px]">
                    </input>
                    <div onClick={() => { if (this.state.tab !== 2) { this.search(); } else { this.clearSearch(); } }}
                        class="h-[50px] w-[80px] text-[18px] text-[#6138D3] bg-white flex justify-center items-center rounded-[10px]">{this.state.tab === 2 ? dict['clear'][di.c] : dict['search'][di.c]}</div>
                </div>
                <div class="h-[20px]"></div>
                <div class="text-[24px] font-bold">{dict['exercises-word'][di.c]}</div>
                <div class="h-[10px]"></div>
                <div class="flex flex-wrap gap-[10px]">
                    <div onClick={() => { this.switchTab(0); }}
                        class={`h-[30px] flex justify-center items-center pl-[15px] pr-[15px] 
                rounded-[10px] ${this.state.tab === 0 ? 'bg-[#6138D3] text-white' : 'bg-white text-black'}`}>{dict['featured'][di.c]}</div>
                    <div onClick={() => { this.switchTab(1); }}
                        class={`h-[30px] flex justify-center items-center pl-[15px] pr-[15px]
                rounded-[10px] ${this.state.tab === 1 ? 'bg-[#6138D3] text-white' : 'bg-white text-black'}`}>{dict['my-exercises'][di.c]}</div>
                    {this.state.tab === 2 ? <div class={`h-[30px] flex justify-center items-center pl-[15px] pr-[15px] rounded-[10px] bg-[#6138D3] text-white`}>{dict['search-results'][di.c]}</div> : <></>}
                    {this.state.cart.length !== 0 ?
                        <div onClick={() => { this.switchTab(3); }}
                            class={`h-[30px] flex justify-center items-center pl-[15px] pr-[15px]
            rounded-[10px] ${this.state.tab === 3 ? 'bg-[#6138D3] text-white' : 'bg-white text-black'}`}>{`${dict['cart'][di.c]} (${this.state.cart.length})`}</div>
                        : <></>}
                </div>
                <div class="h-[20px]"></div>
                <WidgetRenderer cart={this.state.cart} removeFromCart={(obj) => { this.removeFromCart(obj); }}
                    addToCart={(obj) => { this.addToCart(obj); }} exercises={this.state.exercises} />
                {this.state.cart.length !== 0 ? <div class="h-[50px]"></div> : <></>}
                {this.state.cart.length !== 0 ? <div onClick={() => { this.props.addExercises(this.state.cart); }}
                    class="h-[50px] bottom-[19px] left-[19px] fixed w-[calc(100%-38px)]
              rounded-bl-[15px] rounded-br-[15px] flex items-center justify-center text-white bg-[#6138D3]">{`${dict['add'][di.c]} ${this.state.cart.length}
               ${dict['exercises'][di.c]}`}</div> : <></>}
            </div>
        </div>;
    }

}

function WidgetRenderer(props) {
    let ret = [];
    for (let b = 0; b < props.exercises.length; b++) {
        const i = b;
        let obj = props.exercises[i];
        let inCart = props.cart.findIndex((e) => e['id'] === obj['id']) !== -1;
        ret.push(
            <div key={i} class="w-[100%] mb-[20px] flex flex-col items-end">
                {inCart ? <div onClick={() => { props.removeFromCart(obj); }} class="text-red-400 pr-[10px] text-[14px]">{dict['delete'][di.c]}</div> : <></>}
                <div class={`w-[100%] ${inCart ? 'border-solid border-2 border-[#6138D3]' : ''} pl-[15px] pr-[15px] flex flex-row items-center justify-start h-[90px] rounded-[15px] bg-white`}>
                    <div class="w-[calc(100%-24px)] flex flex-row justify-start items-center">
                        <div class="h-[60px] w-[60px] bg-blue-100 rounded-[15px] mr-[15px]">
                            {obj['preview'] !== "" ? <img class="h-[100%] w-[100%] rounded-[15px] object-cover" src={obj['preview']}></img> : <></>}
                        </div>
                        <div class="">{obj['name']}</div>
                    </div>
                    {!inCart ? <AddIcon onClick={() => { props.addToCart(obj); }} sx={{ color: '#6138D3' }} /> : <div class='text-[16px]'>{dict['added'][di.c]}</div>}
                </div>
            </div>
        );
    }
    return <div class="w-[100%] flex flex-col">{ret.map((e) => e)}</div>;
}

export default Search;
import React from 'react';
import dataBus from '../variables';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { di, dict } from '../dict';
const axios = require('axios').default;

class WorkoutPreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            obj: dataBus.workoutPreview,
            ready: false,
        };
        this.prepareExercises();
    }

    async prepareExercises() {
        let ids = this.state.obj['workout_ids'];
        let toadd = [];
        for (let i = 0; i < ids.length; i++) {
            let exists = false;
            for (let c = 0; c < dataBus.exerciseDb.length; c++) {
                if (ids[i] === dataBus.exerciseDb[c]['id']) {
                    exists = true;
                }
            }
            if (!exists) {
                toadd.push(ids[i]);
            }
        }
        if (toadd.length !== 0) {
            const resp = await axios.post(`https://baluan.siranov.com/api/dailyWorkout`, {
                workout_ids: toadd,
            });
            for (let b = 0; b < resp.data.length; b++) {
                dataBus.exerciseDb.push(resp.data[b]);
            }
            this.setState({
                obj: dataBus.workoutPreview,
                ready: true,
            });
        }
    }

    addToPlan(cb) {
        let isAdded = this.isAdded();
        if (!isAdded) {
            let cur = structuredClone(dataBus.user.workouts);
            cur.push(this.state.obj);
            dataBus.user.workouts = cur;
            axios.post(`https://baluan.siranov.com/api/editWorkout`, {
                workouts: cur,
                id: dataBus.user.id,
            });
            cb();
        }
    }

    isAdded() {
        let isAdded = false;
        for (let i = 0; i < dataBus.user.workouts.length; i++) {
            let locid = dataBus.user.workouts[i]['workout_id'] !== undefined ?
                dataBus.user.workouts[i]['workout_id'] : "";
            if (locid === this.state.obj['workout_id']) {
                isAdded = true;
            }
        }
        return isAdded;
    }

    render() {
        let counter = 0;
        return <div class="h-[100%] w-[100%] flex flex-col">
            <div class="w-[100%] aspect-video relative">
                <img class="w-[100%] aspect-video object-cover"
                    src={this.state.obj['cover_image']}></img>
                <div class="absolute top-0 left-0 w-[100%] h-[100%] bg-black/[0.5]" />
                <div class="flex absolute bottom-[10px] left-[10px] text-white flex-col justify-start items-start">
                    <div>{this.state.obj['workout_ids'].length + " "+ dict['exercises'][di.c]}</div>
                    <div class="text-[20px] font-medium">{this.state.obj['name']}</div>
                </div>
                <QuitWrapper
                    child={<div class="absolute h-[40px] w-[40px] top-[10px] right-[10px]
                 bg-black/[0.3] rounded-[100%] text-white flex items-center justify-center">
                        <CloseIcon sx={{ color: 'white' }} />
                    </div>} />
            </div>
            <div class="w-[100%] p-[20px]">
                {this.state.obj['workout_ids'].map((e) => {
                    const i = counter;
                    const id = e;
                    const dbIndex = dataBus.exerciseDb.findIndex((b) => b['id'] === e);
                    const obj = dbIndex !== -1 ? dataBus.exerciseDb[dbIndex] : {};
                    counter++;
                    return <div key={i} class="w-[100%] h-[90px] rounded-[15px]
                 bg-white p-[15px] mb-[20px] flex flex-row justify-start items-center">
                        <div class="h-[60px] w-[60px] rounded-[15px] bg-blue-100">
                            {obj['preview'] !== undefined ? <img class="h-[100%] w-[100%] rounded-[15px] object-cover"
                                src={obj['preview']}></img> : <></>}
                        </div>
                        <div class="w-[15px]" />
                        <div class="font-medium text-start">{obj['name']}</div>
                    </div>;
                })}
            </div>
            <div class="h-[50px]" />
            <AddThenQuit callback={(cb) => { this.addToPlan(cb); }}
                child={<div
                    class={`w-[calc(100%-40px)] h-[50px] fixed bottom-[20px] left-[20px]
             rounded-[15px] ${this.isAdded() ? "bg-gray-500" : "bg-[#6138D3]"} flex items-center justify-center text-white`}>
                    {!this.isAdded() ? dict['add-to-plan'][di.c] : dict['already-added'][di.c]}
                </div>} />
        </div>;
    }
}

function AddThenQuit(props) {
    let navigate = useHistory();
    function trigAndWait() {
        props.callback(() => {
            navigate.push('/edit');
        });
    }
    return <div onClick={() => { trigAndWait(); }}>{props.child}</div>;
}

function QuitWrapper(props) {
    let navigate = useHistory();
    function back() {
        navigate.push(`/${dataBus.workoutParams['from']}`);
    }
    return <div onClick={() => { back(); }}>{props.child}</div>
}

export default WorkoutPreview;
import React from 'react';
import { useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import dataBus from '../variables';
import { di, dict } from '../dict';
import DoneIcon from '@mui/icons-material/Done';
const axios = require('axios').default;

class Settings extends React.Component {

    constructor(props) {
        super(props);
        this.state = ({
            lang: di.c,
            changed: false,
        });
    }

    changeLang(lng) {
        let changed = di.c !== lng;
        this.setState({
            lang: lng,
            changed: changed,
        });
    }

    saveChanges() {
        dataBus.user.language = this.state.lang;
        di.c = this.state.lang;
        this.setState({
            changed: false,
        });
        axios.post(`https://baluan.siranov.com/api/changeLanguage`, {
            id: dataBus.user.id,
            language: this.state.lang,
        });
    }


    render() {
        return <div class="fixed w-[100%] h-[100%] overflow-y-scroll">
            <div class="relative flex flex-col justify-start p-[20px] items-start">
                <div class="h-[50px]" />
                <div class="font-medium text-[20px]">{dict['language'][di.c]}</div>
                <div class="h-[20px]" />
                <div onClick={() => { this.changeLang('kz') }}
                    class="h-[50px] w-[100%] rounded-[15px] pl-[15px] pr-[15px] bg-white items-center flex flex-row justify-between">
                    <div>Казакша</div>
                    <div class="w-[30px] h-[30px] flex justify-center items-center">{this.state.lang === "kz" ? <DoneIcon /> : ""}</div>
                </div>
                <div class="h-[20px]" />
                <div onClick={() => { this.changeLang('en') }}
                    class="h-[50px] w-[100%] rounded-[15px] pl-[15px] pr-[15px] bg-white items-center flex flex-row justify-between">
                    <div>English</div>
                    <div class="w-[30px] h-[30px] flex justify-center items-center">{this.state.lang === "en" ? <DoneIcon /> : ""}</div>
                </div>
            </div>
            {this.state.changed ?
                <div onClick={() => { this.saveChanges(); }}
                    class="fixed bottom-[20px] left-[20px]
             w-[calc(100%-40px)] flex justify-center items-center text-white h-[50px] rounded-[15px] bg-[#6138D3]">{dict['save-changes'][this.state.lang]}</div> :
                <></>}
            <AppBar />
        </div>
    }

}

function AppBar() {
    let history = useHistory();
    function back() {
        history.push("/");
    }
    return <div class="w-[100%] h-[50px] fixed top-0
     left-0 flex items-center pl-[10px] text-center pr-[10px] text-white justify-between bg-[#6138D3]">
        <ChevronLeftIcon onClick={() => { back(); }} sx={{ color: 'white' }} />
        <div>{dict['settings'][di.c]}</div>
        <div class="w-[30px] " />
    </div>;
}


export default Settings;
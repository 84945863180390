import React, { useEffect } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import EditIcon from '@mui/icons-material/Edit';
import './widUtils.css';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import dataBus from '../variables';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import Modal from "react-modal";
import { dict, di } from '../dict';

let timer;
let doneTimer;
let secondTimer;
let lastNum = 0;
let divideBy = 60;
let computerScrolling = false;
let scrolln = 0;


function prepareData(ready, id,) {
    let retObj = { "name": id, "image": "" };
    if (ready) {
        let ind = dataBus.exerciseDb.findIndex((e) => e['id'] === id);
        if (ind !== -1) {
            retObj = dataBus.exerciseDb[ind];
        }
    }
    return retObj;
}

class SetsEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lastNum: 0,
            sets: structuredClone(props.sets),
            activeSet: -1,
            side: 'r',
            timerOn: false,
            showVideo: false, 
        }
        computerScrolling = false;
        lastNum = 0;
        clearInterval(timer);
        clearInterval(doneTimer);
        clearInterval(secondTimer);
        this.getDimensions();
    }

    onModalClose(e) {
        console.log("closing!");
    }

    getDimensions() {
        let t = this.props.type;
        divideBy = (t === 'reps' || t === 'reps_sides') ? 60
            : (t === "hold" || t === "hold_sides") ? 80
                : (t === "distance") ? 100 : 60;
    }

    pickerScrollFunc(e) {
        if (!computerScrolling) {
            let scroll = e.currentTarget.scrollTop;
            let num = Math.round(scroll / 40);
            if (lastNum !== num) {
                lastNum = num;
                let curVal;
                let t = this.props.type;
                if (t === 'reps') {
                    curVal = lastNum;
                } else if (t === 'reps_sides') {
                    curVal = `${lastNum}_${this.state.side}`;
                } else if (t === 'hold') {
                    curVal = `${lastNum}s`;
                } else if (t === 'hold_sides') {
                    curVal = `${lastNum}s_${this.state.side}`
                } else if (t === 'distance') {
                    curVal = `${lastNum * 100}m`
                }
                let curSets = this.state.sets;
                curSets[this.state.activeSet] = curVal;
                this.setState({
                    lastNum: lastNum,
                    sets: curSets,
                });
            }
            clearInterval(timer);
            timer = setInterval(() => { this.scrollTo(true); }, 200);
            if (!this.state.timerOn) {
                clearInterval(doneTimer);
                doneTimer = setInterval(() => { this.probablyDone(); }, 2000);
            }
        }
    }

    probablyDone() {
        if (lastNum !== 0) {
            lastNum = 0;
            this.setState({
                activeSet: -1,
                lastNum: 0,
            });
        }
        computerScrolling = false;
        clearInterval(doneTimer);
        console.log(this.state.sets);
        this.props.updateDb(this.state.sets);
    }

    async addSet() {
        let t = this.props.type;
        let curSets = this.state.sets;
        let curSide = this.state.side;
        curSide = curSide === "r" ? "l" : "r";
        lastNum = 0;
        clearInterval(doneTimer);
        clearInterval(secondTimer);
        if (t === 'reps') {
            curSets.push(0);
        } else if (t === 'reps_sides') {
            curSets.push(`0_${curSide}`);
        } else if (t === 'hold') {
            curSets.push('0s');
        } else if (t === 'hold_sides') {
            curSets.push(`0s_${curSide}`);
        } else if (t === 'distance') {
            curSets.push('0m');
        }
        this.setState({
            sets: curSets,
            activeSet: curSets.length - 1,
            lastNum: 0,
            side: curSide,
            timerOn: false,
        });
        // this.props.updateDb(this.state.sets);
        await sleep(20);
        this.bottomScroll();
    }

    deleteSet() {
        let i = this.state.activeSet;
        let curSet = this.state.sets;
        curSet.splice(i, 1);
        this.setState({
            sets: curSet,
            timerOn: false,
            activeSet: -1,
        });
        this.props.updateDb(this.state.sets);
    }

    sideClicked(side) {
        let curSets = this.state.sets;
        let active = curSets[this.state.activeSet];
        let split = active.split("_");
        curSets[this.state.activeSet] = `${split[0]}_${side}`;
        this.setState({
            side: side,
            sets: curSets,
        });
        if (!this.state.timerOn) {
            clearInterval(doneTimer);
            doneTimer = setInterval(() => { this.probablyDone(); }, 2000);
        }
    }

    async switchTo(i) {
        clearInterval(secondTimer);
        clearInterval(doneTimer);
        computerScrolling = true;
        let t = this.props.type;
        let side = this.state.side;
        if (i !== -1) {
            if (t === "reps") {
                lastNum = this.state.sets[i];
            } else if (t === "reps_sides") {
                lastNum = parseInt(this.state.sets[i].split("_")[0]);
                side = this.state.sets[i].split("_")[1];
            } else if (t === "hold" || t === "hold_sides") {
                lastNum = parseInt(this.state.sets[i].split("s")[0]);
                if (t === "hold_sides") {
                    side = this.state.sets[i].split("_")[1];
                }
            } else if (t === "distance") {
                lastNum = parseInt(this.state.sets[i].split("m")[0]) / 100;
            }
        }
        this.setState({
            activeSet: i,
            lastNum: lastNum,
            side: side,
        });
        await sleep(20);
        this.widgetScroll();
        if (i != -1) {
            this.scrollTo(false);
        }
    }

    repClicked(ind) {
        lastNum = ind;
        let curVal;
        let t = this.props.type;
        if (t === 'reps') {
            curVal = ind;
        } else if (t === 'reps_sides') {
            curVal = `${ind}_${this.state.side}`;
        } else if (t === 'hold') {
            curVal = `${ind}s`;
        } else if (t === 'hold_sides') {
            curVal = `${ind}s_${this.state.side}`
        } else if (t === 'distance') {
            curVal = `${ind * 100}m`
        }
        let curSets = this.state.sets;
        curSets[this.state.activeSet] = curVal;
        this.setState({
            lastNum: ind,
            sets: curSets,
        });
        this.scrollTo(false);
        if (!this.state.timerOn) {
            clearInterval(doneTimer);
            doneTimer = setInterval(() => { this.probablyDone(); }, 2000);
        }
    }

    async scrollTo(normal) {
        computerScrolling = true;
        let scroller = document.getElementById("pickScroll");
        let pos = lastNum * 40;
        clearInterval(timer);
        if (normal) {
            scroller.scroll({ top: pos, left: 0, behavior: "smooth" });
            await sleep(350);
        } else {
            scroller.scrollTo(0, pos);
            await sleep(100);
        }
        computerScrolling = false;
    }

    changeTimer() {
        let prev = this.state.timerOn;
        clearInterval(doneTimer);
        if (prev) {
            clearInterval(secondTimer);
            doneTimer = setInterval(() => { this.probablyDone(); }, 2000);
        } else {
            secondTimer = setInterval(() => { this.everySecond(); }, 1000);
        }
        this.setState({
            timerOn: !prev,
        });
    }

    everySecond() {
        if (this.state.activeSet != -1) {
            let curSets = this.state.sets;
            let active = curSets[this.state.activeSet];
            let rep = parseInt(active.split("s")[0]) + 1;
            curSets[this.state.activeSet] = `${rep}s${active.split("s")[1]}`;
            lastNum = lastNum + 1;
            this.setState({
                sets: curSets,
                lastNum: lastNum,
            });
            this.scrollTo(true);
        } else {
            console.log('clearing 1 second timer...');
            clearInterval(secondTimer);
        }
    }

    closeEditor() {
        clearInterval(secondTimer);
        clearInterval(doneTimer);
        clearInterval(timer);
        lastNum = 0;
        computerScrolling = false;
        this.props.updateDb(this.state.sets);
        this.props.closeEditor();
    }

    async changeEx(val) {
        this.props.changeEx(val);
        await sleep(20);
        this.reinitialize();
    }

    reinitialize() {
        computerScrolling = false;
        lastNum = 0;
        clearInterval(timer);
        clearInterval(doneTimer);
        clearInterval(secondTimer);
        this.setState({
            lastNum: 0,
            sets: structuredClone(this.props.sets),
            activeSet: -1,
            side: 'r',
            timerOn: false,
        });
        this.getDimensions();
    }

    bottomScroll() {
        let sc = document.getElementById('editor-scroll');
        sc.scrollTo({
            top: sc.scrollHeight,
            left: 0,
            behavior: 'smooth'
        });
    }

    widgetScroll() {
        var index = this.state.activeSet;
        var pos = index * 50;
        let sc = document.getElementById('editor-scroll');
        sc.scrollTo({
            top: pos,
            left: 0,
            behavior: 'smooth'
        });
    }


    render() {
        let obj = prepareData(this.props.isReady, this.props.exerciseId);
        return <div class="h-[100%] w-[100%] overflow-scroll bg-transparent"
            id="editor-scroll">
            <div class="relative aspect-video w-[100%]">
                <img class="w-[100%] h-[100%] object-cover" alt=''
                    src={obj['image']}></img>
                <div class="absolute left-0 top-0 h-[100%] w-[100%] bg-black/[0.4]"></div>
                <div class="absolute bottom-[10px] left-[20px] w-[50%] break-words text-white font-bold text-[20px]">{obj['name']}</div>
                {obj['video']!==undefined?<div onClick={()=>{this.setState({showVideo: true});}} 
                class="absolute bottom-[10px] text-white flex justify-center items-center right-[10px] w-[40px] h-[40px]
                 bg-black/[0.4] rounded-[10px]">
                    <OndemandVideoIcon sx={{ color: 'white' }} />
                </div>:<></>}
                <div class="w-[100%] pt-[10px] absolute top-0 right-0 flex flex-row justify-between pl-[10px] pr-[10px]">
                    {this.props.activeExercise !== 0 ?
                        <div onClick={() => { this.changeEx(-1); }} class="h-[40px] w-[40px] rounded-[100%] bg-black/[0.3] flex
                        items-center justify-center text-white"><ChevronLeftIcon /></div>
                        : <div class="h-[2px] w-[2px]"></div>}
                    <div class="w-[90px] h-[40px] flex flex-row justify-end gap-[10px]">
                        {!this.props.isLast ? <div onClick={() => { this.changeEx(1); }} class="h-[40px] w-[40px] rounded-[100%] bg-black/[0.3] flex
                     items-center justify-center text-white"><ChevronRightIcon /></div>
                            : <div class="h-[2px] w-[2px]"></div>}
                        <div onClick={() => { this.closeEditor(); }} class="h-[40px] w-[40px] rounded-[100%] bg-black/[0.3] flex
                     items-center justify-center text-white">
                            <CloseIcon />
                        </div>
                    </div>
                </div>
            </div>
            <div class="h-[20px]"></div>
            <div class="pl-[20px] pr-[20px] flex flex-col items-start">
                <SetsRender
                    sets={this.state.sets} activeSet={this.state.activeSet}
                    type={this.props.type} lastNum={this.state.lastNum}
                    pickerScrollFunc={(e) => { this.pickerScrollFunc(e); }}
                    repClicked={(ind) => { this.repClicked(ind); }}
                    timerOn={this.state.timerOn} changeTimer={() => { this.changeTimer(); }}
                    side={this.state.side} sideClicked={(side) => { this.sideClicked(side); }}
                    addSet={() => { this.addSet(); }} delete={() => { this.deleteSet(); }}
                    switchTo={(i) => { this.switchTo(i) }}
                />
            </div>
            <Modal isOpen={this.state.showVideo}
                preventScroll={true}
                ariaHideApp={false}
                style={{
                    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.8)', overflow: 'scroll' },
                    content: { backgroundColor: '#E6E6E6', overflow: 'auto', overscrollBehavior: 'none' }
                }}
                className="w-[100%] self-center outline-none m-0 p-0 border-0 bg-[#E6E6E6] border-none"
                onRequestClose={() => { this.setState({showVideo: false}); }}
                contentLabel="">
                    <div class="w-[100%] fixed top-[35%] object-cover aspect-video bg-black overflow-hidden flex justify-center items-center">
                    <video width="100%" class="self-center" loop={true} autoPlay={true} src={obj['video']}></video>
                    <div onClick={()=>{this.setState({showVideo: false});}} 
                     class="w-[40px] h-[40px] rounded-[100%] flex items-center justify-center text-white bg-black/[0.4] absolute top-[10px] right-[10px]">
                        <CloseIcon sx={{color: 'white'}}/>
                    </div>
                    </div>
                </Modal>
        </div>;
    }
}

function SetsRender(props) {
    let toRet = [];
    let t = props.type;
    let height = 70;
    if (t.split("_")[1] === "sides") {
        height = height + 40;
    }
    if (t === "hold" || t === "hold_sides") {
        height = height + 60;
    }
    height = `${height}`;
    for (var b = 0; b < props.sets.length + 1; b++) {
        const i = b;
        if (props.activeSet === i) {
            toRet.push(
                <div key={i} class={`w-[100%] mb-[30px] relative`}>
                    <div class="w-[100%] items-end flex flex-row justify-between pb-[5px]">
                        <div class="text-[16px]">{`${dict['set'][di.c]} #${i + 1}`}</div>
                        <div onClick={() => { props.delete(); }} class="text-[14px] text-red-300">{dict['delete'][di.c]}</div>
                    </div>
                    <div class={`relative w-[100%] h-[160px] flex flex-row rounded-[15px] bg-white`}>
                        <div id="pickScroll" onScroll={(e) => { props.pickerScrollFunc(e); }}
                            class="absolute left-[calc(50%-40px)] w-[120px] h-[160px] pt-[60px] self-center flex flex-col overflow-y-scroll">
                            <RepsGenerator onClick={(ind) => { props.repClicked(ind); }} type={props.type} lastNum={props.lastNum} />
                        </div>
                        {t.split("_")[1] === "sides" ?
                            <div class="absolute left-[calc(25%-60px)] top-[40px] flex flex-col items-center justify-center gap-[20px]">
                                <div onClick={() => { props.sideClicked('l'); }} class={`w-[80px] h-[30px] rounded-[10px] ${props.side === "l" ? "bg-[#6138D3]" : "bg-[#E6E6E6]"}
                                     ${props.side === "l" ? "text-white" : "text-black"} flex items-center justify-center`}>left</div>
                                <div onClick={() => { props.sideClicked('r'); }} class={`w-[80px] h-[30px] rounded-[10px] ${props.side === "r" ? "bg-[#6138D3]" : "bg-[#E6E6E6]"}
                                     ${props.side === "r" ? "text-white" : "text-black"} flex items-center justify-center`}>right</div>
                            </div> : <></>}
                        {t === "hold" || t === "hold_sides" ?
                            <div class="absolute right-[calc(25%-20px)] top-[60px] w-[40px] h-[40px] flex items-center justify-center">
                                <div onClick={() => { props.changeTimer(); }} class="w-[40px] h-[40px] rounded-[100%] bg-[#6138D3] flex justify-center items-center">
                                    {!props.timerOn ? <PlayArrowIcon sx={{ color: 'white' }} />
                                        : <PauseIcon sx={{ color: 'white' }} />}
                                </div>
                            </div>
                            : <></>}
                    </div>
                </div>
            );
        } else {
            if (i !== props.sets.length) {
                toRet.push(<CompletedSet onClick={() => { props.switchTo(i) }}
                    set={props.sets[i]} key={i} ind={i} type={props.type} />);
            } else {
                toRet.push(
                    <div key={i} onClick={() => { props.addSet(); }}
                        class="w-[100%] h-[50px] rounded-[15px] flex shadow-[0_4px_4px_0px_rgba(0,0,0,0.3)]
                         items-center justify-center bg-white mb-[40px]  mt-[10px]">{dict['add-set'][di.c] + " +"}</div>
                );
            }
        }
    }
    return toRet.map((e) => e);
}


function CompletedSet(props) {
    let t = props.type;
    let retWids = [];
    let curSet = props.set.toString();
    if (curSet.split("_").length == 2) {
        let split = curSet.split("_");
        let side = split[1] === 'l' ? 'L' : 'R';
        retWids.push(<div key={1} class="text-[16px] text-white flex justify-center items-center h-[20px] w-[60px] rounded-[10px] bg-gray-400">{side}</div>);
        retWids.push(<div key={2} class="text-[20px] pl-[10px]">{split[0]}</div>);
    } else {
        let retText = props.type == "reps" ? props.set : (props.type === "hold" ? (curSet.split("s")[0] + " sec") : props.set);
        retWids.push(<div key={3} class="text-[20px]">{retText}</div>);
    }
    return <div class="w-[100%] mb-[20px]">
        <div class="w-[100%] items-end flex flex-row justify-between pb-[5px]">
            <div class="text-[16px]">{`${dict['set'][di.c]} #${props.ind + 1}`}</div>
        </div>
        <div class="w-[100%] h-[35px] rounded-[15px] bg-white flex flex-row
         justify-center items-center pl-[10px] pr-[10px] relative">
            <div onClick={() => { props.onClick(); }} class="w-[26px] h-[26px] absolute left-[10px]"><EditIcon sx={{ color: 'rgba(0, 0, 0, 0.5)' }} /></div>
            {retWids.map((e) => e)}
        </div>
    </div>;
}

function RepsGenerator(props) {
    let toRet = [];
    let t = props.type;
    let suf = (t === 'reps' || t === 'reps_sides') ? ""
        : (t === "hold" || t === "hold_sides") ? "s"
            : (t === "distance") ? "m" : "";
    let compare = suf === "" ? props.lastNum :
        (suf === "s" ? props.lastNum + "s" : (props.lastNum * 100) + "m");
    let size = divideBy - 20.0;
    for (var i = 0; i < 200; i++) {
        toRet.push(i);
    }
    return toRet.map((b) => {
        let e = (suf === "m" ? b * 100 : b);
        const up = (suf === "m" ? (b + 1) * 100 : b + 1);
        const low = (suf === "m" ? (b - 1) * 100 : b - 1);
        let retText = (suf === "") ? e : e + suf;
        let upperBound = (suf === "") ? up : up + suf;
        let lowerBound = (suf === "") ? low : low + suf;
        return <div onClick={() => { props.onClick(b); }} key={e} class={`w-[80px] min-h-[40px] relative flex justify-center items-center text-center`}>
            {(compare !== retText) ?
                ((compare === upperBound || compare === lowerBound) ?
                    <small class="text-[18px] text-black/[0.5]">{retText}</small> :
                    <small class="text-[16px] text-black/[0.3] ">{retText}</small>)
                : <small class="text-[20px] font-medium">{retText}</small>}
        </div>;
    });
}


function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


export default SetsEdit;
import React from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import dataBus from '../variables';
import { di, dict } from '../dict';

function prepareData(ready, id, ){
    let retObj = {"name": "", "image":""};
    if(ready){
        let ind = dataBus.exerciseDb.findIndex((e)=>e['id'] === id);
        if(ind!==-1){
            retObj = dataBus.exerciseDb[ind];
        }
    }
    return retObj;
}


function Exercise(props) {
    let sets = props.sets;
    let size = 90 + (sets.length !== 0 ? (30 + sets.length * 20 + (sets.length-1)*5) : 0);
    let setsSize = `${30 + sets.length * 20 + sets.length*5}px`;
    size = `${size}px`;
    const obj = prepareData(props.isReady, props.id, {});
    return <div class={`w-[calc(100%-40px)] h-[${size}] ml-[20px] mr-[20px]
     bg-white rounded-[15px] flex flex-col items-center justify-start`}>
        <div onClick={() => { props.onClick(); }}
            class={`h-[90px] p-[15px] w-[100%] relative flex flex-row justify-start items-center`}>
            <div class="w-[calc(100%-44px)] flex flex-row justify-start items-center">
                <div class="h-[60px] w-[60px] bg-blue-200 rounded-[15px]">
                    {props.isReady?<img src={obj['preview']}  class="object-cover h-[60px] w-[60px] rounded-[15px]"></img>:<div></div>}
                </div>
                <div class="w-[20px]"></div>
                <div class="font-medium text-[16px] text-start w-[calc(100%-80px)]">{obj.name}</div>
            </div>
            <div class="w-[20px]"></div>
            {props.showButton?<div class="w-[24px] h-[24px] flex flex-row justify-start items-center"><ExpandLessIcon /></div>:<></>}
        </div>
        <div class={`h-[${setsSize}] w-[100%] pl-[20px] pr-[20px] pb-[15px]`}><SetsRenderer sets={sets} /></div>
    </div>;
}

function SetsRenderer(props) {
    let toRet = [];
    for (var b = 0; b < props.sets.length; b++) {
        let side = props.sets.length !== 0 ? props.sets[b].toString().split("_") : [];
        let isSided = side.length == 2;
        side = isSided ? (side[1] == 'l' ? "L" : "R") : "";
        const i = b;
        let rep = props.sets[i].toString();
        let num;
        let type = 0;
        if (rep.split("s").length === 2) {
            num = parseInt(rep.split("s")[0]);
            num = num + " sec";
        } else if (rep.split("m").length === 2) {
            num = parseInt(rep.split("m")[0]);
            num = num + "m";
        } else {
            type = 1;
            num = isSided?rep.split("_")[0]:rep;
        }
        toRet.push(
            <div key={i} class="text-center flex flex-row justify-between items-center pb-[5px]">
                <div>{`${dict['set'][di.c]} #${i + 1}`}</div>
                <div class={`w-[${type===1?"100":"120"}px] h-[20px] flex flex-row justify-between items-center`}>
                    {isSided ? <div class="h-[20px] w-[60px] rounded-[10px] text-[16px] flex
                     bg-gray-400 text-white items-center justify-center">{side}</div> : <div class="w-[1px] h-[1px]"></div>}
                    <div class={`text-start`}>{num}</div> 
                </div>
            </div>
        );
    }
    return toRet.map((e) => e);
}

export default Exercise;